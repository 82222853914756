import React from 'react'
import './Privacy.css'

const Privacy = () => {
    return (
        <div className='privacy-container px-3'>
            <div className='privacy row'>
                <h1 className='text-center'>
                    Privacy Policy
                </h1>
                <p className=''>
                    The creator of this Platform ensures a steady commitment to Your privacy with regard to the protection of your invaluable information that you may share across this Platform. This privacy policy contains information about the Website www.csp.in (hereinafter referred to as the “Platform”).
                </p>
                <p className='mt-3'>
                    For the purpose of this Privacy Policy (“Privacy Policy”), wherever the context so requires, “We”, “Our”, and “Us” shall mean and refer to the Company or the Platform. “You”, “Your”, “Yourself”, or “User” shall mean and refer to natural and legal individuals who shall be users of this Platform provided by us and who is competent to enter into binding contracts, as per law. “Third Parties” refer to any Mobile Application, platform or legal entities apart from the Users and the Company. The Platform provides You with Our uninterrupted use of the platform. We may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used.
                </p>



                <b>Index:</b>
                <ul className='text-dark'>
                    <li>Personal Information</li>
                    <li>Information We Collect</li>
                    <li>Our Use of Your Information</li>
                    <li>How Information Is Collected</li>
                    <li>External Links on the Platform</li>
                    <li>Cookies</li>
                    <li>Google Analytics</li>
                    <li>Your Rights</li>
                    <li>Compliances</li>
                    <li>Confidentiality</li>
                    <li>Other Information Collectors</li>
                    <li>Our Disclosure of Your Information</li>
                    <li>Security</li>
                    <li>Severability</li>
                    <li>Amendment</li>
                    <li>Consent Withdrawal, Data Download & Data Removal Requests</li>
                    <li>Contact Us</li>
                </ul>




                <b>INFORMATION WE COLLECT</b>
                <p>
                    We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection and management of any Personal Information You share with us. We may collect the following information:
                </p>
                <ol className='text-dark row'>
                    <li>
                        Personal data includes but is not limited to Phone Number, Email id, name, address, location, KYC documents, etc.
                    </li>
                    <li>
                        Information is collected through permission derived by the Platform for internet connection, equipment used to access services, location access and usage details.
                    </li>
                    <li>
                        Tracking Information such as, but not limited to the IP address of your device and Device ID when connected to the Internet. This information may include the URL that you just came from, which URL you next go to, your computer or device browser information, and other information (connection, speed, connection type etc.), device type, device’s unique device identifier, mobile network information and the device’s telephone number associated with your interaction with the Platform.
                    </li>
                    <li>
                        Details of Platform usage for analytics.
                    </li>
                    <li>
                        Bank information, for payment.
                    </li>
                    <li>
                        Search History on the Platform, and
                    </li>
                    <li>
                        Store Preference
                    </li>
                </ol>



                <p>
                    This privacy policy also collects information automatically, about the computer or devices (including mobile devices) you use to access the Services, and about your use of the Services, even if you use the Services without logging and also certain information from users who are not registered as members of this Platform, including, but not limited to:
                </p>
                <ol className='text-dark row'>
                    <li>
                        Usage information: Details of your use of our Services, including traffic data, location data, logs and other communication data and the resources that you access and use on or through our Services.
                    </li>
                    <li>
                        Stored information and files: Our applications also may access metadata and other information associated with other files stored on your mobile device. This may include, for example, photographs, audio and video clips, personal contacts, reviews, comments, current and prior material ordering details and history, favorite Stores, special store requests, contact information of people you add to, or notify of and other information in your account profile and address book information.
                    </li>
                    <li>
                        Mobile device IDs: Unique mobile device identifier (e.g. IDFA or other device IDs on Apple devices like the iPhone and iPad), if you’re using our Services on a mobile device, we may use mobile device IDs (the unique identifier assigned to a device by the manufacturer), instead of cookies, to recognize you. We may do this to store your preferences and track your use of our applications. Unlike cookies, mobile device IDs cannot be deleted. Advertising companies may use device IDs to track your use of our applications, track the number of advertisements displayed, measure advertising performance and display advertisements that are more relevant to you. Analytics companies may use mobile device IDs to track your usage of our applications.
                    </li>
                    <li>
                        Your preferences: Your preferences and settings such as time zone and language.
                    </li>
                    <li>
                        Your activity on the Services: Information about your activity on the Services, such as your search queries, comments, domain names, search results selected, number of clicks, pages viewed and the order of those pages, how long you visited our Services, the date and time you used the Services, error logs, and other similar information.
                    </li>
                    <li>
                        Your transactional information: If you make purchases of services through our Platform, we may collect and store information about you to process your requests and automatically complete forms for future transactions, including (but not limited to) your phone number, address, email, billing information and credit or payment card information. This information may be shared with third-parties which assist in processing and fulfilling your requests, including PCI compliant payment gateway processors. When you submit credit or payment card information, we encrypt the information using industry standard technologies. If you write reviews about businesses with which you conduct transactions through our Services, we may publicly display information that you transacted with those businesses.
                    </li>
                </ol>

                <p>We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:</p>

                <ol>
                    <li>To enable the provision of services opted for by you;</li>
                    <li>To enable the viewing of content in your interest;</li>
                    <li>To communicate the necessary account and service-related information from time to time;</li>
                    <li>To allow you to receive quality customer care services and data Collection;</li>
                    <li>To comply with applicable laws, rules and regulations;</li>
                </ol>

                <p>
                    Where any service requested by You involves a third party, such information as is reasonably necessary by the Company to carry out Your service request may be shared with such third party. We also do use your contact information to send you offers based on your interests and prior activity and also to view the content preferred by you. The Company may also use contact information internally to direct its efforts for service improvement but shall immediately delete all such information upon withdrawal of your consent for the same through the ‘unsubscribe’ button or through an email to be sent to “support@csp.in”.
                </p>
                <p>
                    To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Platform and opt-out of any non-essential communications from the Platform.
                </p>
                <p>
                    Further, transacting over the internet has inherent risks which can only be avoided by following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.
                </p>

                <b>OUR USE OF YOUR INFORMATION</b>
                <p>The information provided by you shall be used to provide and improve the service for you and all users.</p>
                <ol>
                    <li>To provide you with services on your request.
                    </li>
                    <li>
                        For maintaining an internal record.
                    </li>
                    <li>For enhancing the Services provided.
                    </li>
                    <li>
                        For maintaining record under the legal and statutory provisions.
                    </li>
                </ol>

                <p>
                    For more details about the nature of such communications, please refer to our Terms of Service. Further, your data and Sensitive Personal data may be collected and stored by Us for internal record.
                </p>
                <p>
                    We use Your tracking information such as IP addresses, and or Device ID to help identify you and to gather broad demographic information and make further services available to you.
                </p>
                <p>
                    We will not sell, license or trade Your personal information. We will not share your personal information with others unless they are acting under our instructions or we are required to do so by law.
                </p>
                <p>
                    Information collected via Our server logs includes users’ IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how users engage with our Platform so that we can improve it and cater personalized content/ads according to their preferences.
                </p>




                <b>
                    HOW INFORMATION IS COLLECTED
                </b>
                <p>
                    Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. If the same is not identified to you, you have the right to request the Company to elucidate the purpose of collection of said personal information, pending the fulfilment of which you shall not be mandated to disclose any information whatsoever.
                </p>
                <p>
                    We will collect and use your personal information solely to fulfil those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only retain personal information as long as necessary for the fulfilment of those purposes. We will collect personal information by lawful and fair means and with the knowledge and consent of the individual concerned.
                </p>
                <p>
                    Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.
                </p>


                <b>EXTERNAL LINKS ON THE PLATFORM</b>
                <p>
                    The Platform may include advertisements, hyperlinks to other Platforms. We have no control over any other Platforms or resources or contents available on these other Platforms, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services/services or other materials on or available from such Platforms or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, services or other materials on, or available from, such Platforms. These external third-party Platforms and resource providers may have their privacy policies governing the collection, storage, retention and disclosure of Your Personal Information that You may be subject to. We recommend that you enter the Platform and review its Privacy Policy.
                </p>


                <b>GOOGLE ANALYTICS</b>
                <p>We use Google Analytics to help us to understand how you make use of our content and work out how we can make things better. These cookies follow your progress through us, collecting anonymous data on where you have come from, which pages you visit, and how long you spend on the site. This data is then stored by Google to create reports. These cookies do not store your personal data.</p>
                <p>The information generated by the cookie about your use of the Website, including your IP address, may be transmitted to and stored by Google on servers in the United States. Google may use this information for the purpose of evaluating your use of the website, compiling reports on website activity for us and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
                <p>The Google website contains further information about Analytics and a copy of Google’s privacy policy pages.</p>



                <b>YOUR RIGHTS</b>
                <p>Unless subject to an exemption, you have the following rights concerning your data:
                </p>
                <ol>
                    <li>The right to request a copy of your data which we hold about you;
                    </li>
                    <li>
                        The right to request for any correction to any personal data if it is found to be inaccurate or out of date;
                    </li>
                    <li>
                        The right to withdraw Your consent to the processing at any time;
                    </li>
                    <li>The right to object to the processing of personal data;</li>
                    <li>The right to complain about a supervisory authority.
                    </li>
                    <li>The right to obtain information as to whether personal data are transferred to a third country or an international organization.
                    </li>
                </ol>
                <p>Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold concerning you. You are also entitled to request that we restrict how we use your data in your account when you log in.</p>



                <b>COMPLIANCES</b>
                <ol className='row'>
                    <li>
                        Indian Compliances
                        <ol className='row' style={{ 'list-style-type': 'lower-roman' }}>
                            <li>This legal agreement is an electronic record in terms of the Indian Information Technology Act, 2000 and rules there under as applicable and the amended provisions about electronic records in various statutes as amended by the Indian Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
                            <li>This legal document is published in accordance with the provisions of Rule 3 (1) of the Indian Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the Terms of Use and practices for access and usage of any functional Website or Mobile Application.</li>
                        </ol>
                    </li>
                    <li className='row'>GDPR Privacy Rights
                        <p >The General Data Protection Regulation or GDPR gives certain rights to individuals in relation to their personal data. Accordingly, we are happy to offer transparency and access controls to help Users take advantage of those rights. As available, except as limited under applicable law, the rights afforded to individuals are:
                        </p>
                        <ol className='row'>
                            <li>Right of access- the right to be informed of, and request access to, the data we process about you,
                                <ol style={{ 'list-style-type': 'lower-roman' }} className='row'>
                                    <li>Right to rectification – the right to request that we amend or update your data where it is inaccurate or incomplete,
                                    </li>
                                    <li>Right to erasure- the right to request that we delete your data,
                                    </li>
                                    <li>
                                        Right to restrict – the right to request that we temporarily or permanently stop processing all or some of your personal data,
                                    </li>
                                    <li>Right to object – the right to object to us processing your personal data on grounds relating to your particular situation. Along with the right to object to your personal data being processed for direct marketing purposes,</li>
                                    <li>Right to data portability – the right to request a copy of your personal data in electronic format and the right to transmit that personal data for use in another part’s service,</li>
                                    <li>Right not to be subject to automated decision-making, the right to not be subject to a decision based solely on automated decision making, including profiling, where the decision would have a legal effect on you or produce a similarly significant effect.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                </ol>



                <b>
                    CONFIDENTIALITY
                </b>
                <p>You further acknowledge that the Platform may contain information that is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your email address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point in time.
                </p>


                <b>OTHER INFORMATION COLLECTORS
                </b>
                <p>Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Platform or other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their privacy policies. Since we do not control the privacy policies of such third parties, you are subject to ask questions before you disclose your personal information to others.</p>


                <b>OUR DISCLOSURE OF YOUR INFORMATION
                </b>
                <p>We may host surveys for survey creators for our Platform who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses.</p>
                <p>Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made thereunder or any other law for the time being in force.</p>
                <p>Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your registered email address.</p>
                <p>As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:</p>
                <ol className='row'>
                    <li>External Service Providers: There may be several services offered by external service providers that help you use our Platform. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or permit them to collect information about you, then their use of your information is governed by their privacy policy.</li>
                    <li>Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.</li>
                </ol>



                <b>SECURITY</b>
                <p>We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Company. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed.</p>
                <p>However, as effective as encryption technology is, no security system is impenetrable. Our Company cannot guarantee the security of our database, nor can we guarantee that information you provide won’t be intercepted while being transmitted to the Company over the Internet.</p>


                <b>SEVERABILITY</b>
                <p>
                    Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall not affect the remaining paragraphs of this privacy policy.
                </p>


                <b>
                    AMENDMENT
                </b>
                <p>Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be on the Platform. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Platform.</p>


                <b>CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS</b>
                <p>To withdraw your consent, or to request the download or delete your data with us for any or all our services at any time, please email to “support@csp.in”.</p>


                <b>CONTACT US</b>
                <p>If you have any questions or concerns regarding this privacy policy, you should contact us by sending an e-mail to “support@csp.in ” or by writing to us at:</p>


                <b>CSP INDIA NETWORK PVT LTD</b>
                <p>Corp.Off 207, City Avenue by Kolte Patil, near Range Rover ShowRoom, Wakad.</p>
            </div>
        </div>
    )
}

export default Privacy