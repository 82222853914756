import React, { useState, useEffect } from 'react';
import '../../Banking/CspBankingServices/CspBankingServices.css';
import { GetTranlatedValue } from '../../../Data/LanguageContext.js';
import { FinancialServicePageData } from '../../../Data/Services/FinanicalSerivceData.jsx';

const services = [
  { "title": "Personal Loan", "content": require('./CspPersonalLoan.js').default },
  { "title": "Business Loan", "content": require('./CspBuisnessLoan.js').default },
  { "title": "Working Capital Loan", "content": require('./CspWorkingCapitalLoan.js').default },
  // { "title": "Loan Against Mutual Fund", "content": require('./CspLoanMf.js').default },
  { "title": "Credit Line", "content": require('./CspCreditLine.js').default },
  { "title": "Credit Card", "content": require('./CreditCard.js').default },
];

const data2 = [
  {
    title: 'Indian is one of the fastest growing nation and is poised to become the 3rd largest economy in the world from its current state of being the 5 th largest economy. Household consumption, a key determinant to India’s growth is projected to increase to ~INR224 lakh crore (US$ 3 trillion) by FY26, across all income levels.'
  },
  {
    title: 'Growing emphasis on financial inclusion and the steady rise in consumption patterns have led to exceptional growth in India’s financial services industry, particularly in credit availability. The demand for credit in the country has been growing steadily over the years, with a compounded annual growth rate (CAGR) of 14.4% between March 2000 and January FY233. Approximately, 140 million customers were added to the New-To-Credit (NTC) category between FY19 and FY22, with 57% of NTC consumers concentrated in the rural and semi- urban areas. While conventional lending organisations have been the pioneers in meeting credit needs, there is a rapid influx of new technology-led players leading to a digital transformation. Digital lending disbursement is rapidly expanding. Between 2017 and 2020, Digital disbursement has witnessed a 12 fold growth and is expected to surpass INR47.4 lakh crore by 2026.'
  },
  {
    title: 'Strategically, as a Community Entrepreneur of CSP, this is a key enabler as it help them to facilitate their customers at the time of their financial need and also bring to them multiple loan offering and speedy disbursement '
  },
];

const CspFinancialServices = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    setSelectedService(services[0].title);
    setContentVisible(true);
  }, []);

  const handleServiceClick = (title) => {
    setContentVisible(false);
    setTimeout(() => {
      setSelectedService(title);
      setContentVisible(true);
    }, 300);
  };

  const ServiceContent = selectedService ? services.find(service => service.title === selectedService).content : null;

  return (
    <div className="cspbankingservices">
      <div className='container'>
        <div style={{ marginBottom: 54, }}>
          <h2 style={{ textAlign: "center", marginBottom: 20, fontWeight: 700, color: "#2C5363" }}>
            {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.financialheading2)}
          </h2>
          <div className='aeps-all-content'>
            {FinancialServicePageData.FINANCIALSERVICES.financialservicepara.map((item, i) => (
              <div key={i}>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title)}</h4>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title1)}</h4>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title2)}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 csp-col-1 col-md-4'>
            {services.map((item) => (
              <div key={item.title} className={`cspbankingservicescontent ${selectedService === item.title ? 'active' : ''}`} onClick={() => handleServiceClick(item.title)}>
                <h2>{item.title}</h2>
              </div>
            ))}
          </div>
          <div className='col-lg-8 csp-col-2 col-md-8'>
            <div className={`csp-content-container ${contentVisible ? '' : 'hidden'}`}>
              {selectedService && ServiceContent && <ServiceContent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CspFinancialServices;
