import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import kgold from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import sellanytime from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import safety from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import buylow from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import physicalgold from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { InvestmentServicePageData } from '../../../Data/Services/InvestmentServiceData';



const CspMutualfund = () => {


    const data2 = [
        {
            title: 'Mutual funds combine money from many investors to buy a variety of investments. The assets are managed by professional investment managers, who aim to generate returns for the investors. Professional managers decide which investments to buy and sell for the fund. A professional fund manager handles this mix of investments, and its assets and goals are detailed in s funds prospectus.'
        },
        {
            title: 'Apart from the Mutual fund options available in the market, a smart approach in terms of investment for individuals is to invest into Systematic Investment Plans or SIPs. A SIP is a systematic approach to investing and involves allocating a small pre-determined amount of money for investment in the market at regular intervals IT can help inculcate financial discipline and build wealth for the future. With SIPs, one can start small and gradually build a corpus in a systematic and planned manner and allows one to participate in the market  managing risk better.'
        },




    ];





    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>
                    {GetTranlatedValue(InvestmentServicePageData?.INVESTMENTSERVICES?.mutualfundheading)}
                </h1>
                <div className='aeps-all-content'>
                    {InvestmentServicePageData.INVESTMENTSERVICES.mutualfundheadingpara.map((sec, i) => (
                        <div>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                 <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                             </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p className='mt-2'>{GetTranlatedValue(sec.title)}</p>
                                    <p className='mt-2'>{GetTranlatedValue(sec.title1)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='loginlink'>
                    <a href={`${window.location.origin}/version2/auth/login`}><p> {GetTranlatedValue(InvestmentServicePageData?.INVESTMENTSERVICES?.fordeatils)}</p></a>
                </div>

            </div>
        </div>
    );
};

export default CspMutualfund;
