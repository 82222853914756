export const PaymentServicePageData = {
    PAYMENTSERVICES: {
        paymentheading: {
            HINDI: "पेमेंट सेवा",
            ENGLISH: "Bill Payment",
            MARATHI: "Bill Payment"
        },
        paymentheading2: {
            HINDI: "स्कोप और ऑफरिंग",
            ENGLISH: "Scope And Offering",
            MARATHI: "व्याप्ती आणि ऑफरिंग"
        },

        paymentheadingservicepara: [
            {
                title: {
                    HINDI: "अपने ग्राहकों को उनके पड़ोस में उपयोगिता बिलों का ऑनलाइन भुगतान करने में मदद करके, उन्हें कतारों में खड़े होने और उनके समय का प्रबंधन करने की परेशानियों से राहत देकर सुविधा प्रदान करें। सीएसपी आपको भारत बिल भुगतान प्रणाली का उपयोग करके निम्नलिखित बिलों का भुगतान करने में मदद करता है जिसमें मोटे तौर पर बिजली, पानी, फास्टटैग, ऋण ईएमआई और एलआईसी और अन्य बीमा नवीनीकरण प्रीमियम शामिल हैं।",
                    ENGLISH: "Bring convenience to your customers by helping them pay their utility bills online in their neighborhood,relieving them from the hassles of standing in queues and managing their time. CSP helps you, facilitate your customers for the payment of the following bills utilizing the Bharat Bill Payment systems which broadly includes electricity, Water, FastTag, Loan EMI, and LIC and other Insurance Renewal Premium.",
                    MARATHI: "तुमच्या ग्राहकांना त्यांच्या शेजारच्या भागात त्यांची युटिलिटी बिले ऑनलाइन भरण्यास मदत करून, त्यांना रांगेत उभे राहण्याच्या त्रासांपासून मुक्त करून आणि त्यांचा वेळ व्यवस्थापित करून त्यांच्यासाठी सुविधा आणा. CSP तुम्हाला भारत बिल पेमेंट सिस्टमचा वापर करून खालील बिले भरण्यास मदत करते ज्यात वीज, पाणी, फास्टटॅग, कर्ज ईएमआय आणि एलआयसी आणि इतर विमा नूतनीकरण प्रीमियम यांचा समावेश होतो."
                },
                title1: {
                    HINDI: "सीएसपी आपके लिए भारत बिल भुगतान प्रणाली (बीबीपीएस) लेकर आया है, जो एक आरबीआई अनिवार्य प्रणाली है जो लेनदेन की निश्चितता, विश्वसनीयता और सुरक्षा के साथ सभी भौगोलिक क्षेत्रों में ग्राहकों को एकीकृत और अंतर-संचालनीय बिल भुगतान सेवाएं प्रदान करती है।",
                    ENGLISH: "CSP brings to you Bharat Bill Payment System (BBPS), a RBI mandated system which offers integrated and interoperable bill payment services to customers across geographies with certainty, reliability and safety of transactions.",
                    MARATHI: "CSP तुमच्यासाठी भारत बिल पेमेंट सिस्टम (BBPS) घेऊन येत आहे, ही एक RBI अनिवार्य प्रणाली आहे जी संपूर्ण भौगोलिक क्षेत्रांतील ग्राहकांना व्यवहारांची खात्री, विश्वासार्हता आणि सुरक्षिततेसह एकात्मिक आणि परस्पर बिल पेमेंट सेवा देते."
                },
                title2: {

                    HINDI: " बीबीपीएस पर्याप्त दर से बढ़ रहा है। वित्त वर्ष 2022-23 में बीबीपीएस का लेनदेन मूल्य साल-दर-साल (YoY) 66% की वृद्धि तक बढ़ गया है। बीबीपीएस के लिए अब सभी श्रेणियां खुली होने से, लेन-देन की मात्रा अगले पांच वर्षों में वित्त वर्ष 2022-23 में 1.05 बिलियन से चार गुना बढ़कर वित्त वर्ष 2026-27 में 5.4 बिलियन हो जाने की उम्मीद है।",
                    ENGLISH: "BBPS is growing at a substantial rate. The transactional value of BBPS has increased to a Year-on-Year (YoY) growth of 66% in FY 2022–23. With all categories now open for BBPS, the transactional volume is expected to grow by over four times in the next five years from 1.05 billion in FY 2022–23 to 5.4 billion in FY 2026–27.",
                    MARATHI: "BBPS लक्षणीय दराने वाढत आहे. BBPS चे व्यवहार मूल्य आर्थिक वर्ष 2022-23 मध्ये 66% च्या वार्षिक वाढ (YoY) पर्यंत वाढले आहे. आता BBPS साठी सर्व श्रेण्या खुल्या झाल्यामुळे, व्यवहाराचे प्रमाण पुढील पाच वर्षात चार पटीने वाढण्याची अपेक्षा आहे 2022-23 मधील 1.05 अब्ज ते आर्थिक वर्ष 2026-27 मध्ये 5.4 अब्ज पर्यंत."
                },
                title3: {

                    HINDI: " रणनीतिक रूप से, सीएसपी के एक कम्युनिटी इंटरप्रेन्योर के रूप में, यह ग्राहक जुड़ाव और बिक्री संबंध बनाने में मदद करने के लिए एक महत्वपूर्ण प्रवर्तक है क्योंकि आसपास के इलाके के ग्राहक अपने सभी उपयोगिता भुगतानों के लिए नियमित रूप से उनके काउंटर पर आएंगे।",
                    ENGLISH: "Strategically, as a Community Entrepreneur of CSP, this is a critial enabler to help build customer engengment and relationship sell as customers from the surrounding locality will visit their counter regularly for all their utility payments",
                    MARATHI: "धोरणात्मकदृष्ट्या, CSP चे कोम्मुनिटी एन्त्रेप्रेनेऊर म्हणून, हे ग्राहक संलग्नता आणि नातेसंबंध विकण्यास मदत करण्यासाठी एक महत्त्वपूर्ण सक्षमकर्ता आहे कारण आसपासच्या परिसरातील ग्राहक त्यांच्या सर्व उपयोगिता पेमेंटसाठी त्यांच्या काउंटरला नियमितपणे भेट देतील."
                }
            }
        ],

        billpayment: {
            HINDI: "BBPS द्वारे प्रमुख सेवा ",
            ENGLISH: "Key Services throught BBPS",
            MARATHI: "BBPS द्वारे प्रमुख सेवा"
        },

        billpaymentcontent: {
            HINDI: "भारत बिल भुगतान प्रणाली भारतीय रिज़र्व बैंक (RBI) की अवधारणा वाली प्रणाली है जो नेशनल पेमेंट्स कॉरपोरेशन ऑफ़ इंडिया (NPCI) द्वारा संचालित है। यह सभी बिलों के भुगतान के लिए वन-स्टॉप इकोसिस्टम है जो पूरे भारत में सभी ग्राहकों को लेनदेन की निश्चितता, विश्वसनीयता और सुरक्षा के साथ एक इंटरऑपरेबल और सुलभ कभी भी कहीं भी बिल भुगतान सेवा प्रदान करता है।",
            ENGLISH: "The Bharat Bill payment system is a Reserve Bank of India (RBI) conceptualised system driven by National Payments Corporation of India (NPCI). It is a one-stop ecosystem for payment of all bills providing an interoperable and accessible “Anytime Anywhere” Bill payment service to all customers across India with certainty, reliability and safety of transactions.",
            MARATHI: "भारत बिल पेमेंट सिस्टम ही भारतीय रिझर्व्ह बँक (RBI) ची संकल्पना प्रणाली आहे जी नॅशनल पेमेंट कॉर्पोरेशन ऑफ इंडिया (NPCI) द्वारे चालविली जाते. संपूर्ण भारतभरातील सर्व ग्राहकांना व्यवहारांची खात्री, विश्वासार्हता आणि सुरक्षितता यासह इंटरऑपरेबल आणि प्रवेश करण्यायोग्य कधीही कुठेही बिल पेमेंट सेवा प्रदान करणारी सर्व बिले भरण्यासाठी ही एक-स्टॉप इकोसिस्टम आहे."
        },
        billpaymentcontent1: {
            HINDI: "सीएसपी आपके ग्राहकों को बिजली, दूरसंचार, डीटीएच, गैस, पानी के बिल आदि, स्कूल फीस, संस्थान शुल्क, क्रेडिट कार्ड, फास्टैग रिचार्ज, स्थानीय कर, हाउसिंग सोसायटी जैसी बिल संग्रह श्रेणियों की एक विस्तृत श्रृंखला की पेशकश करने में मदद करने के लिए आपके लिए बीबीपीएस लेकर आया है।",
            ENGLISH: "Bharat BillPay has multiple modes of payment and provides instant confirmation of payment via an SMS or receipt. CSP brings to you BBPS to help you offer to your customers a wide range of Bill collection categories like electricity, telecom, DTH, gas, water bills, etc. school fees, institution fees, credit cards, fastag recharge, local taxes, housing society payments, etc. at one single window.",
            MARATHI: "भारत बिलपे मध्ये पेमेंटचे अनेक प्रकार आहेत आणि ते एसएमएस किंवा पावतीद्वारे पेमेंटची त्वरित पुष्टी प्रदान करते. CSP तुमच्या ग्राहकांना वीज, दूरसंचार, डीटीएच, गॅस, पाण्याची बिले इ. शाळेची फी, संस्था फी, क्रेडिट कार्ड, फास्टॅग रिचार्ज, स्थानिक कर, गृहनिर्माण संस्था यासारख्या बिल कलेक्शन श्रेण्यांची विस्तृत श्रेणी ऑफर करण्यात मदत करण्यासाठी CSP तुमच्यासाठी BBPS आणते. एकाच खिडकीवर पेमेंट इ."
        },

        fordeatils: {
            HINDI: "विवरण के लिए csp.in पर लॉग इन करें",
            ENGLISH: "For details log in to csp.in",
            MARATHI: "तपशीलांसाठी csp.in वर लॉग इन करा"
        },

        cashcollectiontheading: {
            HINDI: "कॅश कलेक्शन",
            ENGLISH: "Cash Collection",
            MARATHI: "रोख संकलन"
        },

        cashcollectioncontent: [
            {
                subtitle: {
                    HINDI: "ग्रामीण क्षेत्रों में, जहां बैंकिंग बुनियादी ढांचा सीमित है और डिजिटल साक्षरता का स्तर कम हो सकता है, नकदी, लेनदेन का प्राथमिक तरीका बनी हुई है। हालाँकि, नकदी का कुशलतापूर्वक प्रबंधन करना व्यक्तियों और व्यवसायों दोनों के लिए चुनौतीपूर्ण हो सकता है। यहीं पर बीबीपीएस के माध्यम से सक्षम नकदी प्रबंधन सेवाएं चलन में आती हैं।",
                    ENGLISH: "In rural areas, where banking infrastructure is limited and digital literacy levels may be lower, cash remains the primary mode of transaction. However, managing cash efficiently can be challenging for both individuals and businesses. This is where cash management services enabled through the BBPS come into play.",
                    MARATHI: "ग्रामीण भागात, जिथे बँकिंग पायाभूत सुविधा मर्यादित आहेत आणि डिजिटल साक्षरतेची पातळी कमी असू शकते, तिथे रोख हा व्यवहाराचा प्राथमिक मार्ग आहे. तथापि, रोखीचे कार्यक्षमतेने व्यवस्थापन करणे व्यक्ती आणि व्यवसाय दोघांसाठी आव्हानात्मक असू शकते. येथेच BBPS द्वारे सक्षम रोख व्यवस्थापन सेवा कार्यात येतात."
                },
                title: {
                    HINDI: "",
                    ENGLISH: "",
                    MARATHI: ""
                }
            },
            {
                subtitle: {
                    HINDI: "सीएसपी पर बीबीपीएस नेटवर्क का लाभ उठाकर ग्रामीण निवासी नजदीकी एजेंट स्थानों पर उपयोगिता बिल का भुगतान आसानी से कर सकते हैं। ये सीएसपी बिल भुगतान के लिए टच-प्वाइंट के रूप में कार्य करते हैं, ग्राहकों से नकदी स्वीकार करते हैं और इलेक्ट्रॉनिक रूप से बिलों के निपटान की सुविधा प्रदान करते हैं। यह सुविधा ग्रामीण निवासियों को बिल भुगतान करने के लिए लंबी दूरी तय करने या उपयोगिता कार्यालयों में लंबी कतारों में खड़े होने की आवश्यकता को समाप्त कर देती है।",
                    ENGLISH: "By leveraging the BBPS network at the customer service points rural residents can make utility bill payments conveniently at nearby agent locations. These CSPs act as touchpoints for bill payments, accepting cash from customers and facilitating the settlement of bills electronically. This convenience eliminates the need for rural residents to travel long distances to make bill payments or stand in long queues at utility offices.",
                    MARATHI: "ग्राहक सेवा केंद्रांवर BBPS नेटवर्कचा लाभ घेऊन ग्रामीण भागातील रहिवासी जवळपासच्या एजंटच्या ठिकाणी सोयीस्करपणे युटिलिटी बिल पेमेंट करू शकतात. हे CSP बिल पेमेंटसाठी टचपॉइंट म्हणून काम करतात, ग्राहकांकडून रोख रक्कम स्वीकारतात आणि इलेक्ट्रॉनिक पद्धतीने बिल सेटलमेंट करतात. या सुविधेमुळे ग्रामीण रहिवाशांना बिल भरण्यासाठी लांब पल्ल्याचा प्रवास करण्याची किंवा उपयुक्तता कार्यालयात लांब रांगेत उभे राहण्याची गरज नाहीशी होते."
                },
                title: {
                    HINDI: "सुविधा",
                    ENGLISH: "Convenience",
                    MARATHI: "सुविधा"
                }
            },
            {
                subtitle: {
                    HINDI: "बीबीपीएस के माध्यम से दी जाने वाली नकदी प्रबंधन सेवाएं सुरक्षा और पारदर्शिता को प्राथमिकता देती हैं। लेन-देन के डिजिटल रिकॉर्ड की उपलब्धता पारदर्शिता प्रदान करती है, जिससे ग्राहक अपने भुगतान और प्राप्तियों को आसानी से ट्रैक कर सकते हैं।",
                    ENGLISH: "Cash management services offered through the BBPS prioritize security and transparency. Availability of digital records of transactions provide transparency, allowing customers to track their payments and receipts easily.",
                    MARATHI: "BBPS द्वारे ऑफर केलेल्या रोख व्यवस्थापन सेवा सुरक्षा आणि पारदर्शकतेला प्राधान्य देतात. व्यवहारांच्या डिजिटल रेकॉर्डची उपलब्धता पारदर्शकता प्रदान करते, ज्यामुळे ग्राहकांना त्यांची देयके आणि पावत्या सहजपणे ट्रॅक करता येतात."
                },
                title: {
                    HINDI: "सुरक्षा और पारदर्शिता:",
                    ENGLISH: "Security and Transparency:",
                    MARATHI: "सुरक्षा आणि पारदर्शकता:"
                }
            },
        ],

        loanpaymentheading: {
            HINDI: "लोन रीपेमेंट ",
            ENGLISH: "Loan Repayment",
            MARATHI: "कर्ज परतफेड"
        },

        loanpaymentcontent: {
            HINDI: "सीएसपी अपने प्लेटफॉर्म पर बीबीपीएस के माध्यम से आपके लिए लोन रीपेमेंट की सुविधा लाता है अपने ग्राहकों को समय पर और बिना किसी परेशानी के उनके ऋण ईएमआई का भुगतान करने में सहायता करें। चूंकि अधिकांश ईएमआई मासिक प्रकृति की होती है, इससे आपके ग्राहक के साथ दीर्घकालिक संबंध बनाने में मदद मिलेगी और आपको उन्हें विभिन्न उत्पादों और सेवाओं की पेशकश करने का अवसर मिलेगा।",
            ENGLISH: "CSP, through BBPS on its platform, brings to you the facility of loan repayment. Help your customers pay their Loan EMI on time and without any hassle. Since most of the EMI’s are monthly in nature this will help in building a long term relationship with your customer and provide you an opportunity to offer to them various products and services",
            MARATHI: "CSP, त्याच्या प्लॅटफॉर्मवर BBPS द्वारे, तुमच्यासाठी विमा प्रीमियम परतफेडीची सुविधा आणते. तुमच्या ग्राहकांना त्यांच्या कर्जाचा EMI वेळेवर आणि कोणत्याही त्रासाशिवाय भरण्यास मदत करा. बहुतेक ईएमआय मासिक स्वरूपाचे असल्याने यामुळे तुमच्या ग्राहकांशी दीर्घकालीन संबंध निर्माण करण्यात मदत होईल आणि तुम्हाला त्यांना विविध उत्पादने आणि सेवा ऑफर करण्याची संधी मिळेल."
        },
        insuranceheading: {
            HINDI: "बीमा प्रीमियम भुगतान",
            ENGLISH: "Insurance Premium",
            MARATHI: "विम्याचा हप्ता"
        },

        insurancecontent: {
            HINDI: "सीएसपी अपने प्लेटफॉर्म पर बीबीपीएस के माध्यम से आपके लिए लोन रीपेमेंट की सुविधा लाता है अपने ग्राहकों को समय पर और बिना किसी परेशानी के उनके ऋण ईएमआई का भुगतान करने में सहायता करें। चूंकि अधिकांश ईएमआई मासिक प्रकृति की होती है, इससे आपके ग्राहक के साथ दीर्घकालिक संबंध बनाने में मदद मिलेगी और आपको उन्हें विभिन्न उत्पादों और सेवाओं की पेशकश करने का अवसर मिलेगा।",
            ENGLISH: "CSP, through BBPS on its platform, brings to you the facility of Insurance Premium repayment. Help your customers pay their Loan EMI on time and without any hassle. Since most of the EMI’s are monthly in nature this will help in building a long term relationship with your customer and provide you an opportunity to offer to them various products and services",
            MARATHI: "CSP, त्याच्या प्लॅटफॉर्मवर BBPS द्वारे, तुमच्यासाठी विमा प्रीमियम परतफेडीची सुविधा आणते. तुमच्या ग्राहकांना त्यांच्या कर्जाचा EMI वेळेवर आणि कोणत्याही त्रासाशिवाय भरण्यास मदत करा. बहुतेक ईएमआय मासिक स्वरूपाचे असल्याने यामुळे तुमच्या ग्राहकांशी दीर्घकालीन संबंध निर्माण करण्यात मदत होईल आणि तुम्हाला त्यांना विविध उत्पादने आणि सेवा ऑफर करण्याची संधी मिळेल."
        },
        rechargeheading: {
            HINDI: "रिचार्ज",
            ENGLISH: "Recharge",
            MARATHI: "रिचार्ज"
        },

        rechargecontent: {
            HINDI: "सीएसपी अपने प्लेटफॉर्म पर बीबीपीएस के माध्यम से आपके लिए लोन रीपेमेंट की सुविधा लाता है अपने ग्राहकों को समय पर और बिना किसी परेशानी के उनके ऋण ईएमआई का भुगतान करने में सहायता करें। चूंकि अधिकांश ईएमआई मासिक प्रकृति की होती है, इससे आपके ग्राहक के साथ दीर्घकालिक संबंध बनाने में मदद मिलेगी और आपको उन्हें विभिन्न उत्पादों और सेवाओं की पेशकश करने का अवसर मिलेगा।",
            ENGLISH: "One of the most significant advantages of using BBPS for bill recharges is the convenience it offers. Whether it is topping up prepaid mobile phones, paying postpaid mobile bills, or settling electricity bills, BBPS provides a hassle-free experience, saving time and effort for users.Transparency is another key feature of BBPS-enabled bill recharges. Users receive instant confirmation of their transactions, along with digital receipts for their records.Help the customers in your locality to make their recharge payments hassle free, instant and transparent.",
            MARATHI: "CSP, त्याच्या प्लॅटफॉर्मवर BBPS द्वारे, तुमच्यासाठी विमा प्रीमियम परतफेडीची सुविधा आणते. तुमच्या ग्राहकांना त्यांच्या कर्जाचा EMI वेळेवर आणि कोणत्याही त्रासाशिवाय भरण्यास मदत करा. बहुतेक ईएमआय मासिक स्वरूपाचे असल्याने यामुळे तुमच्या ग्राहकांशी दीर्घकालीन संबंध निर्माण करण्यात मदत होईल आणि तुम्हाला त्यांना विविध उत्पादने आणि सेवा ऑफर करण्याची संधी मिळेल."
        },

    }
}