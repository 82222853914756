export const BankingServicePageData = {
    BANKINGSERVICES: {
        bankingheading: {
            HINDI: "बैंकिंग सेवाएं",
            ENGLISH: "Banking Services",
            MARATHI: "बँकिंग सेवा"
        },
        bankingheading2: {
            HINDI: "स्कोप और ऑफरिंग",
            ENGLISH: "Scope And Offering",
            MARATHI: "व्याप्ती आणि ऑफरिंग"
        },
        Bankingservicepara: [
            {
                title: {
                    HINDI: "उपलब्ध रिपोर्टों के अनुसार, लगभग 190 मिलियन भारतीय अभी भी बैंक अकाउंट या बैंकिंग सुविधाओं से वंचित हैं, यह इंटरनेट के उपयोग में भारी वृद्धि और भौगोलिक क्षेत्रों में पहुंच के संदर्भ में इसकी कवरेज और स्मार्ट फोन बाजार की वृद्धि के बावजूद है।",
                    ENGLISH: "As per the available reports, nearly 190 million Indians still remain unbanked. This is despite the steep surge in Internet usage and its coverage in terms of access across geographies and also the staggering growth of the smart phone market.",
                    MARATHI: "उपलब्ध अहवालांनुसार, सुमारे 190 दशलक्ष भारतीय अद्यापही बँक रहित आहेत, हे इंटरनेट वापरामध्ये प्रचंड वाढ आणि भौगोलिक क्षेत्रांमध्ये प्रवेशाच्या दृष्टीने त्याचे कव्हरेज आणि स्मार्ट फोन बाजाराची आश्चर्यकारक वाढ असूनही आहे."
                },
                title1: {

                    HINDI: " गैर-बैंकों और ग्रामीण भौगोलिक क्षेत्रों में बैंकिंग सेवाओं को सक्षम बनाने के लिए, वित्तीय समावेशन को सुविधाजनक बनाने के लिए, वर्ष 2016 में आधार इनेबल्ड पेमेंट सिस्टम्स ( AePS) शुरू की गई थी और वित्त वर्ष 24 में, आधार सक्षम भुगतान प्रणाली (एईपीएस) के  लेनदेन की स्वीकृत मात्रा 5533 मिलियन थी, जो वित्त वर्ष 17 से उल्लेखनीय वृद्धि है जब मात्रा 335 मिलियन थी (स्रोत एनपीसीआई)। ये संख्या तेजी से बढ़ते डिजिटल लेनदेन और इसकी क्षमता का एक मजबूत संकेत हैं। यहां एक तथ्य उल्लेखनीय यह है कि आंतरिक प्रवासी आबादी (अंतर और राज्य के भीतर) की मात्रा लगभग 45 करोड़ है जो देश की आबादी का लगभग 37% है। यह एईपीएस या डीएमटी जैसे लेनदेन के डिजिटल तरीकों के उपयोग के उपलब्ध दायरे को दर्शाता है।,",
                    ENGLISH: "In order to enable banking services in the unbanked, rural geographies and facilitate financial inclusion, Aadhar enabled payment Systems (AePS) was introduced in the year 2016 and in the FY 24, the volume approved of transactions for Aadhaar Enabled Payment System (AePS) was 5533 million on, a significant increase from FY 17 when the volume was 335 million (Source NPCI ). These numbers are a strong indicative of the exponentially growing digital transactions and its potential. A fact worth mentioning here is the quantum of internal migratory population (both Inter & Intra state) is around 45Cr which is roughly around 37% of country’s population. This speaks volumes about the available scope of usage of digital modes of transaction like AePS or DMT. A clear win for operators of such services is the convenience that they offer to their customers in terms of availability and access.",
                    MARATHI: "बँकिंग सेवा सक्षम करण्यासाठी आणि ग्रामीण भागात आर्थिक समावेश सुलभ करण्यासाठी, आधार सक्षम पेमेंट सिस्टम (AePS) सन 2016 मध्ये सुरू करण्यात आली आणि FY24 मध्ये, आधार सक्षम पेमेंट सिस्टम (AEPS) साठी मंजूर झालेल्या व्यवहारांचे प्रमाण 5533 दशलक्ष होते, जे 335 दशलक्ष (स्रोत NPCI) असताना FY17 च्या तुलनेत लक्षणीय वाढ आहे. हे आकडे वेगाने वाढणाऱ्या डिजिटल व्यवहारांचे आणि त्याच्या संभाव्यतेचे द्योतक आहेत. येथे नमूद करण्यासारखे एक तथ्य म्हणजे अंतर्गत स्थलांतरित लोकसंख्येचे प्रमाण (आंतर आणि आंतरराज्य दोन्ही) सुमारे 45Cr आहे जे देशाच्या लोकसंख्येच्या अंदाजे 37% आहे. हे AePS किंवा DMT सारख्या व्यवहाराच्या डिजिटल पद्धतींच्या वापराच्या उपलब्ध व्याप्तीबद्दल बोलते. ऑपरेटर्ससाठी अशा सेवांचा एक स्पष्ट विजय म्हणजे ते त्यांच्या ग्राहकांना उपलब्धता आणि प्रवेशाच्या दृष्टीने ऑफर करतात."
                },
                title2: {

                    HINDI: "रणनीतिक रूप से हमारे कम्युनिटी इंटरप्रेन्योर के लिए यह सर्विस काफी कारगर है क्योंकि आवश्यक सेवा होने के कारण काउंटर्स पे कस्टमर्स की उपस्थिथि निरंतर बनाये रखेगा और व्यवसाय में सक्रियता भी।",
                    ENGLISH: "Strategically, as a Community Entrepreneur, this is a critial enabler as being an essential service, this will ensure constant footfall of customers at their counter and help them stay relevant in buisness, always!",
                    MARATHI: "धोरणात्मकदृष्ट्या, CSP चे कोम्मुनिटी एन्त्रेप्रेनेऊर म्हणून, ही सेवा महत्त्वपूर्ण आहे, यामुळे ग्राहकांची त्यांच्या काउंटरवर सतत गर्दी होईल आणि त्यांना व्यवसायात नेहमीच संबंधित राहण्यास मदत होईल!"
                }
            }
        ],
        bankingaeps: {
            HINDI: "आधार इनेबल्ड पेमेंट सिस्टम्स ( AePS) ",
            ENGLISH: "Aadhaar Enabled Payment System (AePS)",
            MARATHI: "आधार एनॅब्लेड पेमेंट सिस्टम (AePS):"
        },
        aepsheading: {
            HINDI: "आधार इनेबल्ड पेमेंट सिस्टम्स ( AePS) ",
            ENGLISH: "Aadhaar Enabled Payment System (AePS)",
            MARATHI: "आधार एनॅब्लेड पेमेंट सिस्टम (AePS):"
        },
        aepscontent: {
            HINDI: "एक भुगतान सेवा है जो एक बैंक ग्राहक को अपने आधार खाते तक पहुंचने के लिए अपनी पहचान के रूप में आधार का उपयोग करने की अनुमति देता है और बैलेंस पूछताछ, नकद निकासी, एक बैंकिंग कोरेस्पोंडेंट के माध्यम से धन भेजने जैसे बुनियादी बैंकिंग ट्रांसक्शन्स प्रदान करने में सहायक रहता है।",
            ENGLISH: "Aadhaar Enabled Payment System (AePS) is a payment service that allows a bank customer to use Aadhaar as his/her identity to access his/her Aadhaar account and perform basic banking transactions like balance enquiry, cash withdrawal, remittances through a Business Correspondent.",
            MARATHI: "आधार एनॅब्लेड पेमेंट सिस्टम (AePS) ही एक पेमेंट सेवा आहे जी बँक ग्राहकाला त्याच्या/तिच्या आधार खात्यात प्रवेश करण्यासाठी आणि बॅलन्सची चौकशी, रोख पैसे काढणे, बिझनेस करस्पॉन्डंटद्वारे पैसे पाठवणे यासारखे मूलभूत बँकिंग व्यवहार करण्यासाठी आधार वापरण्याची परवानगी देते."
        },
        aepsoffer: {
            HINDI: " AePS द्वारा प्रदान की जाने वाली बैंकिंग सेवाएं इस प्रकार हैं:- ",
            ENGLISH: "Banking services offered by AePS :-",
            MARATHI: "AePS द्वारे ऑफर केलेल्या बँकिंग सेवा:-"
        },
        aepsofferlist: [
            {
                title: {
                    HINDI: "नकद जमा",
                    ENGLISH: "Cash Deposit",
                    MARATHI: "रोख ठेव"
                },
            },
            {
                title: {
                    HINDI: "नकद निकासी",
                    ENGLISH: "Cash Withdrawal",
                    MARATHI: "पैसे काढणे"
                },

            },
            {
                title: {
                    HINDI: "आधार से आधार मनी ट्रान्सफर ",
                    ENGLISH: "Aadhar to AAdhar Money transfer",
                    MARATHI: "आधार ते आधार मनी ट्रान्सफर"
                },

            },
            {
                title: {

                    HINDI: "मिनी स्टेटमेंट",
                    ENGLISH: "Mini statement",
                    MARATHI: "मिनी सस्टेटमेंट"
                },

            },
        ],
        fordeatils: {
            HINDI: "विवरण के लिए csp.in पर लॉग इन करें",
            ENGLISH: "For details log in to csp.in",
            MARATHI: "तपशीलांसाठी csp.in वर लॉग इन करा"
        },

        moneytransfercontent: {
            HINDI: " डोमेस्टिक मनी ट्रांसफर एक अनूठा उत्पाद है, जिसका उपयोग भारत में किसी भी बैंक के खाताधारक को तुरंत धन भेजने के लिए किया जा सकता है। इस उत्पाद के माध्यम से कोई भी भारतीय नागरिक कस्टमर सर्विस पॉइंट में जा कर विवरण प्रदान कर अपने परिवार या परिजनों को मनी ट्रांसफर कर सकता है।",
            ENGLISH: "Domestic Money Transfer (DMT) is a unique product that can be used to send money instantly to any Bank’s account holder within India. Through this product any Indian citizen can walk in with cash-in- hand to any nearest Customer Service Point (CSP), fill in few details and have the money transferred to there known ones, anywhere in the country.",
            MARATHI: "देशांतर्गत मनी ट्रान्सफर (डीएमटी) हे एक अद्वितीय उत्पादन आहे ज्याचा वापर भारतातील कोणत्याही बँकेच्या खातेदाराला त्वरित पैसे पाठवण्यासाठी केला जाऊ शकतो. या उत्पादनाद्वारे कोणताही भारतीय नागरिक कोणत्याही जवळच्या ग्राहक सेवा बिंदूवर (CSP) कॅश-इन हँडसह जाऊ शकतो, काही तपशील भरू शकतो आणि देशात कुठेही, ज्ञात असलेल्यांना पैसे हस्तांतरित करू शकतो."
        },
        moneytranferheading: {
            HINDI: "डोमेस्टिक मनी ट्रांसफर (DMT)",
            ENGLISH: "Domestic Money Transfer (DMT)",
            MARATHI: "देशांतर्गत मनी ट्रान्सफर (डीएमटी) "
        },
        moneytransferoffer: {
            HINDI: "इस परिदृश्य के माध्यम से ट्रांसक्शन करने के लिए किसी ग्राहक के पास निम्नलिखित का होना आवश्यक है.  ",
            ENGLISH: "The inputs required for a customer to do a transaction under this scenario are :-",
            MARATHI: "या परिस्थितीत ग्राहकाला व्यवहार करण्यासाठी आवश्यक असलेले इनपुट हे आहेत:-"
        },
        moneytransferofferlist: [
            {
                title: {
                    HINDI: "आईआईएन (जिस बैंक से ग्राहक जुड़ा हुआ है)",
                    ENGLISH: "IIN (Identifying the Bank to which the customer is associated)",
                    MARATHI: "	IIN (ग्राहक ज्या बँकेशी संबंधित आहे ती ओळखणे)"
                },
            },
            {
                title: {
                    HINDI: "आधार नंबर",
                    ENGLISH: "Aadhaar Number",
                    MARATHI: "आधार क्रमांक"
                },

            },
            {
                title: {
                    HINDI: "नामांकन के दौरान हुई फिंगरप्रिंट कैप्चर ",
                    ENGLISH: "Fingerprint captured during their enrollment",
                    MARATHI: "त्यांच्या नावनोंदणी दरम्यान फिंगरप्रिंट घेतले"
                },

            },
            {
                title: {

                    HINDI: "मिनी स्टेटमेंट",
                    ENGLISH: "Mini statement",
                    MARATHI: "मिनी सस्टेटमेंट"
                },
            },
        ],
        accountopeningheading: {
            HINDI: "बैंक खाता ",
            ENGLISH: "Account Opening",
            MARATHI: "खाते उघडणे"
        },
        accountopeningcontent: {
            HINDI: " अपने ग्राहकों को प्रतिस्पर्धी ब्याज दरों पर विभिन्न बैंकों के साथ ऑनलाइन बचत खाता खोलने में मदद करें। हमारे पास निर्बाध बैंकिंग अनुभव के साथ विभिन्न बैंकों के बचत खतों की ऑफरिंग्स उपलब्ध है। ",
            ENGLISH: "Help your customers to open savings account online with various banks at competitive interest rates. We offer different types of savings account with seamless banking experience.",
            MARATHI: "विविध बँकांमध्ये स्पर्धात्मक व्याजदरात बचत खाते ऑनलाइन उघडण्यासाठी तुमच्या ग्राहकांना मदत करा. आम्ही अखंड बँकिंग अनुभवासह विविध प्रकारचे बचत खाते ऑफर करतो."
        },
        qratmheading: {
            HINDI: "क्यूआर एटीएम",
            ENGLISH: "QR ATM",
            MARATHI: "क्यूआर एटीएम"
        },
        Qratmcontent: {
            HINDI: "यूपीआई-एटीएम एक इंटरऑपरेबल कार्डलेस कैश विड्रॉल (आईसीसीडब्ल्यू) सेवा है जो यूपीआई पर रहने वाले बैंक के ग्राहकों को अपने कार्ड का उपयोग किए बिना किसी भी प्रतिभागी बैंक के एटीएम (यूपीआई-एटीएम के लिए सक्षम) से नकदी निकालने की सुविधा प्रदान करती है।",
            ENGLISH: "UPI-ATM is an Interoperable Cardless Cash Withdrawal (ICCW) service that facilitates participating banks customers who are live on UPI, to withdraw cash from any participating banks ATMs (enabled for UPI-ATM) without using their card.",
            MARATHI: "UPI-ATM ही इंटरऑपरेबल कार्डलेस कॅश विथड्रॉव्हल (ICCW) सेवा आहे जी UPI वर लाइव्ह असलेल्या सहभागी बँक ग्राहकांना त्यांचे कार्ड न वापरता कोणत्याही सहभागी बँकांच्या ATM (UPI-ATM साठी सक्षम) मधून पैसे काढण्याची सुविधा देते."
        },
        qratmkeyfeature: {
            HINDI: "प्रमुख विशेषताऐं",
            ENGLISH: "Key features",
            MARATHI: "महत्वाची वैशिष्टे"
        },
        qratmofferlist: [
            {
                title: {
                    HINDI: "डेबिट कार्ड के बिना आसान निकासी",
                    ENGLISH: "Easy Withdrawal without Debit card",
                    MARATHI: "डेबिट कार्डशिवाय सहज पैसे काढणे"
                },
            },
            {
                title: {
                    HINDI: "आसान बैंक निपटान ",
                    ENGLISH: "Easy Bank settlement",
                    MARATHI: "सुलभ बँक सेटलमेंट"
                },

            },
        ],

        microatmheading: {
            HINDI: "माइक्रो एटीएम",
            ENGLISH: "Micro ATM",
            MARATHI: "मायक्रो एटीएम"
        },

        microatmcontent: {
            HINDI: "माइक्रो एटीएम एक ऐसा उपकरण है जो CSP को बुनियादी बैंकिंग सेवाएं प्रदान करने की सुविधा देता है। यह प्लेटफ़ॉर्म एक सीएसपी (उदाहरण के लिए एक स्थानीय किराना दुकान के मालिक जो 'माइक्रो एटीएम' के रूप में कार्य करेगा) को तत्काल लेनदेन करने में सक्षम बनाता है। माइक्रो प्लेटफॉर्म कम लागत वाले उपकरणों (माइक्रो एटीएम) के माध्यम से कार्य करता है जो देश भर के बैंकों से जुड़े होते हैं। यह किसी व्यक्ति को किसी भी बैंक से तुरंत धन जमा करने या निकालने में सक्षम बनाता है, भले ही BC किसी भी बैंक से जुड़ा हो। ग्राहकों को अपने बैंक खातों से पैसे निकालने या जमा करने के लिए अपनी पहचान प्रमाणित करानी होगी। माइक्रो एटीएम द्वारा समर्थित बुनियादी लेनदेन प्रकार जमा, निकासी, फंड ट्रांसफर और बैलेंस पूछताछ हैं।",
            ENGLISH: "Micro ATM is a device which facilitates CSPs to deliver basic banking services. The platform will enable the CSP’s (who could be a local kirana shop owner and will act as ‘micro ATM’) to conduct instant transactions. The micro platform functions through low cost devices (micro ATMs) that is connected to banks across the country. This enables a person to instantly deposit or withdraw funds regardless of the bank associated with a particular BC. This device is based on a mobile phone. Customers have to get their identity authenticated and withdraw or put money into their bank accounts. The basic transaction types, to be supported by micro ATM, are Deposit, Withdrawal, Fund transfer and Balance enquiry.",
            MARATHI: "मायक्रो एटीएम हे एक उपकरण आहे जे CSP ला मूलभूत बँकिंग सेवा वितरीत करण्यासाठी सुविधा देते. प्लॅटफॉर्म CSP ला (जो स्थानिक किराणा दुकान मालक असू शकतो आणि 'मायक्रो एटीएम' म्हणून काम करेल) त्वरित व्यवहार करण्यास सक्षम करेल. मायक्रो प्लॅटफॉर्म कमी किमतीच्या उपकरणांद्वारे (मायक्रो एटीएम) कार्य करते जे देशभरातील बँकांशी जोडलेले आहे. हे एखाद्या विशिष्ट बीसीशी संबंधित बँकेची पर्वा न करता एखाद्या व्यक्तीला त्वरित निधी जमा करण्यास किंवा काढण्यास सक्षम करते. हे उपकरण मोबाईल फोनवर आधारित आहे. ग्राहकांना त्यांची ओळख प्रमाणित करून त्यांच्या बँक खात्यात पैसे काढावे किंवा टाकावे लागतील. मायक्रो एटीएमद्वारे समर्थित मूलभूत व्यवहाराचे प्रकार म्हणजे ठेव, पैसे काढणे, निधी हस्तांतरण आणि शिल्लक चौकशी."
        },
    }
}
