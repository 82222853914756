import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { FinancialServicePageData } from '../../../Data/Services/FinanicalSerivceData';

const CreditCard = () => {


    const ServicesAeps = [
        {
            title: 'Credit Line',
            paragraph: 'Data trends indicate a rise in popularity of credit cards in India, with the total number hitting the 100 million mark in. in February 2024, with 1.1 million card additions from the previous month as per the latest data released by the Reserve Bank of India showed. In February 2023, the total cards in circulation stood at 99.5 million. Based on this trend, it is predicted that by FY 2027, the total value of credit card transactions will reach INR 51.72 trillion, growing at a CAGR of 39.22% between FY 2022 and FY 2027. During the same forecast period, anticipations are that the number of credit card transactions will also increase at a CAGR of 26.43%. These statistics reflect that the role of credit cards in India will likely increase. As per the RBI, 65% of the credit card penetration is present in tier I cities. Comparatively, the penetration in tier II and III cities has been significantly less. This presents a huge scope in terms of customer acquisition. CSP brings to you multiple credit card options to provide to your customers the power of choice. '
        },
    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginLeft: 20, marginBottom: 40 }}>
                    {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.creditcardheading)}
                </h1>
                <ul className="bottom-list">
                    {FinancialServicePageData.FINANCIALSERVICES.creditcardpara.map((sec, i) => (
                        <li>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                 <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                             </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p className=''>{GetTranlatedValue(sec.title)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                    <div className='loginlink'>
                    <a href={`${window.location.origin}/version2/auth/login`}><p> {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.fordeatils)}</p></a>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default CreditCard;
