import React, { useState, useEffect } from 'react';
import '../../Banking/CspBankingServices/CspBankingServices.css';
import { GetTranlatedValue } from '../../../Data/LanguageContext.js';
import { InvestmentServicePageData } from '../../../Data/Services/InvestmentServiceData.jsx';

const services = [
  {
    "title": "Digital Gold",
    "content": require('./CspDigitalGold.js').default
  },
  {
    "title": "Mutual Fund",
    "content": require('./CspMutualfund.js').default
  },
  {
    "title": "Demat Accounts",
    "content": require('./CspDemataccount.js').default
  },
  // {
  //   "title": "Micro ATM",
  //   "content": require('../../Banking/CspBankingServices/InnerServices/MicroAtm.js').default
  // },
  // {
  //   "title": "Account Opening",
  //   "content": require('../../Banking/CspBankingServices/InnerServices/AccountOpening.js').default
  // },
];
const data2 = [
  {
    title: 'Investment for individuals in India is about making strategic decisions to allocate resources in away that maximizes returns, achieves financial objectives, and enhances overall financial well-being. It requires careful planning, research, and ongoing monitoring to adapt to changing smarket conditions and personal circumstances.'
  },
  {
    title: 'Investment options in semi-urban and rural India are indispensable for driving economic growth, fostering financial inclusion, creating wealth, mitigating risks, promoting entrepreneurship, spurring infrastructure development, and supporting social progress. By channeling resources effectively and empowering local communities, these investment avenues play a vital role in the holistic development of the nation.'
  },
  {
    title: 'As a Community Entrepreneur of CSP, one has an opportunity to bring to their customers, investment options, which can help yield handsome return and also impact customer behavior by motivating them to save more.'
  },
];

const CspInvestmentServices = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    setSelectedService(services[0].title);
    setContentVisible(true);
  }, []);

  const handleServiceClick = (title) => {
    setContentVisible(false);
    setTimeout(() => {
      setSelectedService(title);
      setContentVisible(true);
    }, 300);
  };

  const ServiceContent = selectedService ? services.find(service => service.title === selectedService).content : null;

  return (
    <div className="cspbankingservices">
      <div className='container'>
        <div style={{ marginBottom: 54, }}>
          <h2 style={{ textAlign: "center", marginBottom: 20, fontWeight: 700, color: "#2C5363" }}>
            {GetTranlatedValue(InvestmentServicePageData?.INVESTMENTSERVICES?.investmentheading2)}
          </h2>
          <div className='aeps-all-content'>
            {InvestmentServicePageData.INVESTMENTSERVICES.investmentservicepara.map((item, i) => (
              <div key={i}>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title)}</h4>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title1)}</h4>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title2)}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 csp-col-1 col-md-4'>
            {services.map((item) => (
              <div key={item.title} className={`cspbankingservicescontent ${selectedService === item.title ? 'active' : ''}`} onClick={() => handleServiceClick(item.title)}>
                <h2>{item.title}</h2>
              </div>
            ))}
          </div>
          <div className='col-lg-8 csp-col-2 col-md-8'>
            <div className={`csp-content-container ${contentVisible ? '' : 'hidden'}`}>
              {selectedService && ServiceContent && <ServiceContent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CspInvestmentServices;
