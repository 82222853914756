export const LANGUAGES = {
    HINDI: "HINDI",
    ENGLISH: "ENGLISH",
    MARATHI: "MARATHI"
}

export const GetTranlatedValue = (value) => {
    let type = localStorage.getItem("language");
    switch (type) {
        case "HINDI":
            return value?.HINDI;
        case "ENGLISH":
            return value?.ENGLISH;
        case "MARATHI":
            return value?.MARATHI;
        default:
            return value.ENGLISH;
    }
}


export const GetFontValue = () => {
    let type = localStorage.getItem("language");
    switch (type) {
        case "HINDI":
            return "Tiro Devanagari Hindi";
        case "ENGLISH":
            return "Montserrat";
        case "MARATHI":
            return "Tiro Devanagari Hindi";
        default:
            return "";
    }
}


export const GetWordDictonary = () => {
    let type = localStorage.getItem("language");
    switch (type) {
        case "HINDI":
            return ['मनी ट्रांसफर', 'एईपीएस', 'बिल पेमेंट', 'लोन', 'डिजिटल गोल्ड', 'ट्रेन और बस टिकट', 'ई-गवर्नेंस'];
        case "ENGLISH":
            return ['Money Transfer', 'AePS', ' Bill Payment', 'Loan', 'Digital Gold', 'Train & Bus Ticket', 'E-Governance'];
        case "MARATHI":
            return ['मनी ट्रान्सफर', 'एईपीएस', 'बिल पेमेंट', 'लोन', 'डिजिटल गोल्ड', 'ट्रेन आणि बस तिकीट', 'ई-गव्हर्नन्स'];
        default:
            return [ 'Money Transfer', 'AePS', ' Bill Payment', 'Loan', 'Digital Gold', ' Train & Bus Ticket', 'E-Governance'];
    }
}