import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg';
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { FaqPageData } from '../../../Data/FaqData';

const DigitalGoldFaq = () => {

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>
                    {GetTranlatedValue(FaqPageData?.FAQHEADING?.faqheading)}
                </h1>
                <ul className="bottom-list">
                    {FaqPageData.FAQHEADING.digitalgoldque.map((sec, i) => (
                        <li key={i}>
                            <div className='d-flex gap-2'>
                                <div className='mt-3'>
                                    <img src={dot} style={{ width: 17 }} alt="dot"></img>
                                </div>
                                <div className='py-2'>
                                    <p><strong>{GetTranlatedValue(sec.title)}</strong></p>
                                    <p>{GetTranlatedValue(sec.subtitle)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className=' text-start'>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>{GetTranlatedValue(FaqPageData?.FAQHEADING?.bbpstableTH1)}</th>
                                <th>{GetTranlatedValue(FaqPageData?.FAQHEADING?.bbpstableTH2)}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>360</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>410</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>510</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>699</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>{GetTranlatedValue(FaqPageData?.FAQHEADING?.Disclaimer1)}</p>
                    <p>{GetTranlatedValue(FaqPageData?.FAQHEADING?.Disclaimer2)}</p>

                </div>
            </div>
        </div>
    );
};

export default DigitalGoldFaq;
