import React from 'react';
import './InnerServices.css';
import dot from './dot-circle-svgrepo-com.svg'
import offers from '../../img/microatm/matm-transaction-reward.png'
import debitcard from '../../img/microatm/matm-all-debit-cards-accepted.png'
import safesecure from '../../img/microatm/matm-safe-secure.png'
import besttech from '../../img/microatm/matm-best-tech.png'
import { GetTranlatedValue } from '../../../../Data/LanguageContext';
import { BankingServicePageData } from '../../../../Data/Services/BankingServiceData';



const MicroAtm = () => {
    const data = [
        {
            imageUrl: offers,
            title: 'Get upto 10 Rs per Transaction'
        },
        {
            imageUrl: debitcard,
            title: 'All Debit Cards Accepted'
        },
        {
            imageUrl: safesecure,
            title: 'Safe And Secure'
        },

        {
            imageUrl: besttech,
            title: 'Best Technology'
        },

    ];

    const data2 = [

        {
            title: 'Micro ATM is a device which facilitates CSPs to deliver basic banking services. The platform will enable the CSP’s (who could be a local kirana shop owner and will act as ‘micro ATM’) to conduct instant transactions. The micro platform functions through low cost devices (micro ATMs) that is connected to banks across the country. This enables a person to instantly deposit or withdraw funds regardless of the bank associated with a particular BC. This device is based on a mobile phone. Customers have to get their identity authenticated and withdraw or put money into their bank accounts. The basic transaction types, to be supported by micro ATM, are Deposit, Withdrawal, Fund transfer and Balance enquiry.'
        },

    ];



    return (
        <div>
            <div className="aeps-content">
                <h2 style={{ textAlign: "left", marginBottom: 40 }}>
                    {GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.microatmheading)}

                </h2>
                <div className='row align-items-center'>
                    {data.map((item, index) => (
                        <div key={index} className="col-lg-2 col-4">
                            <img src={item.imageUrl} alt={item.title} />
                            <h3>{item.title}</h3>
                        </div>
                    ))}
                </div>
                <div className='aeps-all-content'>
                    <div>
                        <h4 style={{ color: "#000" }}>{GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.microatmcontent)}</h4>
                    </div>
                </div>
                <div className='loginlink'>
                    <a href={`${window.location.origin}/version2/auth/login`}><p> {GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.fordeatils)}</p></a>
                </div>
            </div>
        </div>
    );
};

export default MicroAtm;
