import React from 'react'
import CourierHome from './CourierHome'
import CourierService from './CourierService'

const Courier = () => {
  return (
    <div>
        <div>
            <CourierHome/>
            <CourierService/>
        </div>
    </div>
  )
}

export default Courier