import React from 'react'
import './ReadMore.css'

const GrowingEntrepreneurialAspirations = () => {
    return (
        <>
            <div className='read-more-container'>
                <div className='read-more-main-container d-flex align-items-center justify-content-center'>
                    <div className='header text-center text-white'>
                        <h1>
                            75th Year Of Independence and Growing Entrepreneurial
                            Aspirations
                        </h1>
                    </div>
                </div>
            </div>
            <div className='read-more-main-container'>
                <div className='row mt-5'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='blog-intro'>
                            <div className='intro row'>
                                <h2>Introduction</h2>
                                <p>
                                    As we celebrate our 75th year of Independence, we bow with reverence for
                                    the countless sacrifices and contributions by our fellow countrymen in
                                    shaping our country to its present position. We, Indians are truly blessed
                                    with resilience and a testimony to this is our fight back from the impact
                                    that the pandemic had on our lives both morally as well as economically.
                                </p>
                                <p>
                                    On the Economic front our progress since our independence has been
                                    significant. We have shaped our economy to the fifth largest in the world.
                                    An article titled the ‘State of the Economy' published in the January
                                    bulletin of the RBI and authored by a team lead by RBI Deputy Governor
                                    Michael Debabrata Patra said, "At current prices and exchange rates,
                                    therefore, India will be a USD 3.7 trillion economy in 2023, maintaining its
                                    lead over the UK as the fifth largest economy of the world."
                                </p>
                                <p>
                                    As per available forecasts, India is projected to move into fourth place in
                                    2025 and into third place in 2027 as a USD 5.4 trillion economy.
                                </p>
                                <p>
                                    We must also acknowledge the contribution we have made in the global
                                    entrepreneurial ecosystem. There has been a significant growth in the
                                    number of start-ups in India. As per the Global Entrepreneurship India
                                    report which has a reference to the States Start-up Ranking 2021, published
                                    by the Department for Promotion of Industry and Internal Trade, Ministry of
                                    Commerce and Industry, GOI. India is the third largest start-up ecosystem in the
                                    world with almost 73,000 start-ups spread across 56 diverse sectors. The country
                                    has seen unprecedented growth in the number of unicorns which are over 100
                                    now. Indian start-ups raised funding of over $42 billion in 2021 with over $11
                                    billion funding in the first quarter of 2022, there is a nine times increase in
                                    the number of investors and seven times increase in the total funding for
                                    start-ups and the number of incubators. Despite an economic downturn
                                    during the COVID-19, there has been a heavy increase in traffic on digital
                                    media. The main sectors that emerged for start-ups during COVID-19 are
                                    digital education, Fintech, health and wellbeing, shared office space, and
                                    remote working tools
                                </p>
                                <p>
                                    Proactive government support through various schemes and visibility
                                    campaigns has played a critical to this successful start-up story. The Start-
                                    up India mission, launched on 16 January 2016, has seen tremendous
                                    success in building the start-up ecosystem of the country.
                                </p>
                                <p>
                                    Union Budget 2022 presented the blue print of India at 75 to India at 100
                                    Identifying inclusive development, productivity enhancement, energy
                                    transition, and climate action as the four pillars of development. Some of
                                    the proposed steps for facilitating   business, start-ups, and the promotion
                                    of entrepreneurship are:
                                </p>
                            </div>
                            <div>
                                <ul className='blog-ul row'>
                                    <li>
                                        The holiday scheme for start-ups is extended till 31 March 2023 to
                                        incentivise funding for these businesses. This will enable start-ups to get a
                                        100% tax rebate as long as their annual turnover is under Rs 25 crore in a
                                        financial year.
                                    </li>
                                    <li>
                                        A fund with blended capital raised under the co-investment model
                                        facilitated through NABARD to finance start-ups in agriculture and rural
                                        enterprises for the farm-produce value chain will be set up. Further, start-
                                        ups would support farmer-producer organisations (FPOs) and offer tech
                                        support to farmers.
                                    </li>
                                    <li>
                                        Ease of Doing Business 2.0 aims to digitize manual processes, remove
                                        overlapping compliances, and promote the integration of state- and
                                        central-level systems. Business and Entrepreneurship Perspectives in
                                        India During Post-Covid-19:
                                    </li>
                                    <li>
                                        The concessional corporate tax of 15% will be extended for another year
                                        till March 2024 for newly incorporated manufacturing enterprises.
                                    </li>
                                    <li>
                                        Promoting start-ups and extending drone support for farmers through
                                        Kisan Drones for crop assessment.
                                    </li>
                                    <li>
                                        Digital Rupee will be issued using block chain technology.
                                    </li>
                                </ul>
                            </div>
                            <div className='intro row'>
                                <p>
                                    While the stage is all set to register a more emphatic growth, a noteworthy
                                    paradigm shift of late years has been in the mind-set of the common man, many
                                    of whom now, look forward to pursue their aspiration of entrepreneurship, and
                                    perceive it as a practical reality which would help them become self- reliant and
                                    an option more lucrative than employment. The growth in entrepreneurship
                                    would lead to further mushrooming of  new business set ups and ultimately
                                    making a contribution to economy and employment generation.
                                </p>
                                <p>
                                    Also, there are multiple opportunities available which leverage digitisation and
                                    provide an option of income generation
                                </p>
                            </div>
                            <div>
                                <ul className='blog-ul row'>
                                    <li>
                                        <b>AEPS(AdhaarEnabledPaymentSystem) and Bill Payment system’s</b>
                                        <br />
                                        AePS is a bank led model which allows online interoperable financial
                                        inclusion   transaction   at   PoS   (MicroATM)   through   the   Business
                                        correspondent   of   any   bank   using   the   Aadhaar   authentication.   This
                                        initiative has led to start of several Tech aggregators who are aligned to
                                        individuals in rural as well as semi urban geographies, who in turn extend
                                        services to customers. Thus this initiative has helped at three levels:
                                        Providing Entrepreneurship options at aggregator and individual levels and
                                        benefitting the customers in the underserviced geographies by bringing
                                        hordes of online services
                                    </li>
                                    <li>
                                        <b>Banking Correspondents</b>
                                        <br />
                                        An initiative by banks towards financial inclusion and to facilitate banking
                                        at unbanked locations. This has seen rise of corporate business
                                        correspondents as well as Individual business correspondents. This
                                        initiative has brought empowerment to Individuals as entrepreneurs and
                                        has facilitated customers as well.

                                    </li>
                                    <li>
                                        <b>Online Product Aggregator Platform</b>
                                        <br />
                                        Many online platforms offer opportunities for small time businesses to sell
                                        their merchandise online through their platform. This has helped local
                                        entrepreneurs promote their handicrafts or merchandise nationally.
                                    </li>
                                </ul>
                            </div>
                            <div className='intro row'>
                                <p>
                                    The above are only a few examples which we have witnessed in last few
                                    years and have made a significant impact to by touching lives, providing
                                    livelihood   and   helping   change   course   of   the   society   towards
                                    entrepreneurship.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GrowingEntrepreneurialAspirations