import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { FinancialServicePageData } from '../../../Data/Services/FinanicalSerivceData';

const CspWorkingCapitalLoan = () => {



    const ServicesAeps = [

        {
            title: 'Working capital Loan',
            paragraph: 'Working capital forms the lifeblood of any business operation, irrespective of its scale or location. It represents the funds needed to cover day-to-day expenses such as salaries, utilities, inventory, and overhead costs. Unlike traditional term loans used for capital expenditure, working capital loans are specifically designed to meet short-term operational needs. '
        },
        {
            title: 'Working capital Loan',
            paragraph: 'These loans may be tailored to address the cyclical nature of businesses, providing the necessary liquidity to bridge gaps between revenue generation and expenditure. '
        },
        {
            title: 'Working capital Loan',
            paragraph: 'CSP helps you offer to your customers a customized working capital loan for small and medium enterprises to meet their working capital requirements'
        },


    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginLeft: 20, marginBottom: 20 }}>
                    {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.workingcapitalheading)}
                </h1>

                <ul className="bottom-list">
                    {FinancialServicePageData.FINANCIALSERVICES.workingcapitalervicepara.map((sec, i) => (
                        <li>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                 <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                             </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p>{GetTranlatedValue(sec.title)}</p>
                                    <p className='mt-2'>{GetTranlatedValue(sec.title1)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                    <div className='loginlink'>
                    <a href={`${window.location.origin}/version2/auth/login`}><p> {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.fordeatils)}</p></a>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default CspWorkingCapitalLoan;
