import React, { useState, useEffect } from 'react';
import './CspBankingServices.css';
import { GetTranlatedValue } from '../../../Data/LanguageContext.js';
import { BankingServicePageData } from '../../../Data/Services/BankingServiceData.jsx';

const services = [
  { "title": "AePS", "content": require('./InnerServices/Aeps.js').default },
  { "title": "Money Transfer", "content": require('./InnerServices/MoneyTransfer.js').default },
  { "title": "Account Opening", "content": require('./InnerServices/AccountOpening.js').default },
  { "title": "QR ATM", "content": require('./InnerServices/QrAtmService.js').default },
  { "title": "Micro ATM", "content": require('./InnerServices/MicroAtm.js').default },
];

const data2 = [
  {
    title: 'As per the available reports, nearly 190 million Indians still remain unbanked, this is despite the steep surge in Internet usage and its coverage in terms of access across geographies and also the staggering growth of the smart phone market. '
  },
  {
    title: 'In order to enable banking services in  the unbanked and the rural geographies, also facilitate financial inclusion, Aadhar enabled payment Systems (AePS) was introduced in the year 2016 and by February 23, around 380 million transactions were recorded in India. These numbers are a strong indicative of the exponentially growing digital transactions and its potential. A fact worth mentioning here is the quantum of internal migratory population (both Inter & Intra state) is around 45Cr which is roughly around 37% of country’s population. This speaks volumes about the available scope of usage of digital modes of transaction like AePS or DMT. A clear win for operators such services is the convenience that they offer to their customers in terms of availability and access. '
  },
  {
    title: 'Strategically, as a Community Entrepreneur, this is a critial enabler as being an essential service, this  will a constant footfall of customers at their counter and help them stay relevant in buisness, always!'
  },
];

const CspBankingServices = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    setSelectedService(services[0].title);
    setContentVisible(true);
  }, []);

  const handleServiceClick = (title) => {
    setContentVisible(false);
    setTimeout(() => {
      setSelectedService(title);
      setContentVisible(true);
    }, 300);
  };

  const ServiceContent = selectedService ? services.find(service => service.title === selectedService).content : null;

  return (
    <div className="cspbankingservices">
      <div className='container'>
        <div style={{ marginBottom: 54, }}>
          <h2 style={{ textAlign: "center", marginBottom: 20, fontWeight: 700, color: "#2C5363" }}>
            {GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.bankingheading2)}
          </h2>
          <div className='aeps-all-content'>
            {BankingServicePageData.BANKINGSERVICES.Bankingservicepara.map((item, i) => (
              <div key={i}>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title)}</h4>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title1)}</h4>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title2)}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 csp-col-1 col-md-4'>
            {services.map((item) => (
              <div key={item.title} className={`cspbankingservicescontent ${selectedService === item.title ? 'active' : ''}`} onClick={() => handleServiceClick(item.title)}>
                <h2>{item.title}</h2>
              </div>
            ))}
          </div>
          <div className='col-lg-8 csp-col-2 col-md-8'>
            <div className={`csp-content-container ${contentVisible ? '' : 'hidden'}`}>
              {selectedService && ServiceContent && <ServiceContent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CspBankingServices;
