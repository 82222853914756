import './RoeSection.css'
import { GetTranlatedValue } from '../../Data/LanguageContext'
import { HomePageData } from '../../Data/HomePageData'

const RoeSection = () => {
    return (
        <div className='' style={{ backgroundColor: '#2c5363', paddingTop: 80, paddingBottom: 50 }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="roesection-content">
                            <h2 style={{ marginBottom: 14 }}>  {GetTranlatedValue(HomePageData?.ROESECTION?.roeheading)}</h2>
                            <p>{GetTranlatedValue(HomePageData?.ROESECTION?.roetext)}</p>
                        </div>
                    </div>

                    <div className='col-lg-7'>
                        <div className='roe-column2'>
                            <div className="roesection-content2">
                                <h2>
                                    <p>{GetTranlatedValue(HomePageData?.ROESECTION?.offering)}</p>
                                </h2>
                            </div>
                            {HomePageData.ROESECTION.roegrowth.map((sec, i) => (
                                <div className='row align-items-center roe_list' key={i}>
                                    <div className='col-lg-5'>
                                        <div className='roe-col2-content'>
                                            <img src={sec.img} style={{ width: 40 }} alt="icon" />
                                            <h2>{GetTranlatedValue(sec.title)}</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-7'>
                                        <div className='roe-col2-content'>
                                            <p>{GetTranlatedValue(sec.subtitle)}</p>
                                        </div>
                                    </div>
                                    {i !== HomePageData.ROESECTION.roegrowth.length - 1 && (
                                        <img className='' src='https://signdesk.com/images/about-us/green_underline.png' alt="underline" />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RoeSection;
