export const NavbarPageData = {
    NAVBARSERVICES: {
        services: {
            HINDI: "सेवाएँ",
            ENGLISH: "Services",
            MARATHI: "सेवा"
        },
        aboutus: {
            HINDI: "हमारे बारे में",
            ENGLISH: "About Us",
            MARATHI: "आमच्याबद्दल"
        },
        resources: {
            HINDI: "संसाधन",
            ENGLISH: "Resources",
            MARATHI: "संसाधने"
        },
        contact: {
            HINDI: "संपर्क करें",
            ENGLISH: "Contact Us",
            MARATHI: "आमच्याशी संपर्क साधा"
        },
        login: {
            HINDI: "लॉगिन",
            ENGLISH: "Login",
            MARATHI: "लॉगिन"
        },
        joinnow: {
            HINDI: "ज्वाइन करिये ",
            ENGLISH: "Join Now",
            MARATHI: "सामील व्हा"
        },
        Language: {
            HINDI: "भाषा",
            ENGLISH: "Language",
            MARATHI: "भाषा"
        },
    }
}