import CspEgovHome from "./CspEgoHome"
import CspEgovServices from "./EgovServices/CspEgovServices"


const CspEgov = () => {
    return (
        <div className="about">
            <CspEgovHome />
            <CspEgovServices />
        </div>
    )
}
export default CspEgov