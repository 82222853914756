import React from 'react';
import './InnerServices.css';
import dot from './dot-circle-svgrepo-com.svg'
import cashdeposit from '../../img/aeps/aeps-cash-deposit.png'
import cashwithdrawal from '../../img/aeps/aeps-cash-withdrawal.png'
import balanceenq from '../../img/aeps/aeps-balance-enquiry.png'
import ministatement from '../../img/aeps/aeps-mini-statement.png'
import aadharpay from '../../img/aeps/aeps-aadhaar-to-aadhaar.png'
import fingerprint from '../../img/aeps/aeps-finger-detection.png'
import { GetTranlatedValue } from '../../../../Data/LanguageContext';
import { BankingServicePageData } from '../../../../Data/Services/BankingServiceData';


const AepsContainer = () => {
    // Example data
    const data = [
        {
            imageUrl: cashdeposit,
            title: 'Cash Deposit'
        },
        {
            imageUrl: cashwithdrawal,
            title: 'Cash Withdrawal'
        },
        {
            imageUrl: balanceenq,
            title: 'Balance Enquiry'
        },
        {
            imageUrl: ministatement,
            title: 'Mini Statement'
        },
        {
            imageUrl: aadharpay,
            title: 'Aadhaar Pay'
        },

    ];

    const data2 = [
        {
            title: 'Aadhaar Enabled Payment System (AEPS) is a payment service that allows a bank customer to use Aadhaar as his/her identity to access his/her Aadhaar account and perform basic  banking transactions like balance enquiry, cash withdrawal, remittances through a Business Correspondent.'
        },

    ];

    const bottomListItems = [
        'Cash Deposit',
        'Cash Withdrawal',
        'Aadhar to AAdhar Money transfer',
        'Mini statement'
    ];


    return (
        <div>
            <div className="aeps-content">
                <h2 style={{ textAlign: "left", marginLeft: 10, marginBottom: 20 }}>
                    {GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.aepsheading)}
                </h2>
                <div className='row '>
                    {data.map((item, index) => (
                        <div key={index} className="col-lg-2 col-4  col-content">
                            <img src={item.imageUrl} alt={item.title} />
                            <h3>{item.title}</h3>
                        </div>
                    ))}
                </div>
                <div className='aeps-all-content'>

                    <div>
                        <h4 style={{ color: "#000" }}>{GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.aepscontent)}</h4>
                    </div>
                </div>
                <h3 style={{ color: "#333", textAlign: "left", fontSize: 16, paddingBottom: 10 }}>
                    {GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.aepsoffer)}
                </h3>
                <ul className="bottom-list">
                    {BankingServicePageData.BANKINGSERVICES.aepsofferlist.map((sec, i) => (
                        <li>
                            <div className='d-flex align-items-center gap-2'>
                                <div>
                                    <img src={dot} style={{ width: 17 }}></img>
                                </div>
                                <div>
                                    <p>{GetTranlatedValue(sec.title)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className='loginlink'>
                    <a href={`${window.location.origin}/version2/auth/login`}><p> {GetTranlatedValue(BankingServicePageData?.BANKINGSERVICES?.fordeatils)}</p></a>
                </div>
            </div>
        </div>
    );
};

export default AepsContainer;
