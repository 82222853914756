import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { FinancialServicePageData } from '../../../Data/Services/FinanicalSerivceData';
import { egovernanceServicePageData } from '../../../Data/Services/EGovernanceData';

const CspPersonalLoan = () => {


    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginLeft: 20, marginBottom: 20, }}>
                    {GetTranlatedValue(egovernanceServicePageData?.EGOVERNANCESERVICES?.dscheading)}
                </h1>
                {/* <div className='aeps-all-content'>
                    {data2.map((item, index) => (
                        <div key={index}>
                            <h4 style={{ color: "#000" }}>{item.title}</h4>
                        </div>
                    ))}
                </div> */}
                {/* <h3 style={{ color: "#333", textAlign: "left", fontSize: 16, paddingBottom: 10 }}>Our Community Entrepreneur’s can now offer to their customers benefits
                    of the following lending products :-</h3> */}
                <ul className="bottom-list">
                    {egovernanceServicePageData.EGOVERNANCESERVICES.dscservicepara.map((sec, i) => (
                        <li>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p>{GetTranlatedValue(sec.title)}</p>
                                    <p className='mt-2'>{GetTranlatedValue(sec.title1)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                    <div className='loginlink'>
                        <a href={`${window.location.origin}/version2/auth/login`}><p> {GetTranlatedValue(FinancialServicePageData?.FINANCIALSERVICES?.fordeatils)}</p></a>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default CspPersonalLoan;
