import React from 'react'
import './AboutHome.css'
import AboutImg from '../../../IMG-20241108-WA0003.jpg'
import { GetTranlatedValue } from '../../Data/LanguageContext'
import { AboutPageData } from '../../Data/AboutPageData'
const AboutHome = () => {
    return (
        <div className='about-home-container'>
            <div className='container'>
                <div className='row flex-row-reverse'>
                    <div className='col-sm-6 col-12 p-5' style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <img src={AboutImg} alt='aboutimg' style={{borderRadius:12}}/>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-12' style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <div className='about-left-content text-white d-flex flex-column'>
                            <div className='about-home-content d-flex flex-column'>
                                <p>
                                    {
                                        GetTranlatedValue(AboutPageData.ABOUTHEADING.aboutheading1)
                                    }
                                </p>
                                <p>
                                    {
                                        GetTranlatedValue(AboutPageData.ABOUTHEADING.aboutheading2)
                                    }
                                </p>
                                <p>
                                    {
                                        GetTranlatedValue(AboutPageData.ABOUTHEADING.aboutheading3)
                                    }
                                </p>
                                <p>
                                    {
                                        GetTranlatedValue(AboutPageData.ABOUTHEADING.aboutheading4)
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutHome