import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import kgold from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import sellanytime from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import safety from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import buylow from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import physicalgold from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import { GetTranlatedValue } from '../../../Data/LanguageContext';
import { FinancialServicePageData } from '../../../Data/Services/FinanicalSerivceData';
import { InvestmentServicePageData } from '../../../Data/Services/InvestmentServiceData';



const CspDigitalGold = () => {


    const data2 = [
        {
            title: 'For us, Indians, Gold holds a sentimental value, purchasing gold is not only an investment but is akin to a familial prestige. The growing consumption trend of gold in India is indicative of the  central role that gold plays in the country’s culture and its consideration as a symbol of wealth.  A 2018 Niti Aayog report   attributes 60% of gold sale to rural India.'
        },
        {
            title: 'With technology being a key enabler in expanding the reach of the digital gold Segment, there is scope for this market to grow and fulfil the liquidity and capital needs of individuals and businesses'
        },
        {
            title: 'Investing in digital gold is considered to be a cost-effective and efficient way of investing in gold. Each unit of digital gold is backed by 24K over 99% purity gold. One can buy gold with an amount as low as INR 100. The purchase and sale happens online at market prices.'
        },
        {
            title: ' CSP in Strategic alliance with  Safegold ,a digital platform that allows customers to buy, sell and receive vaulted gold at low ticket sizes, brings to you to provide to your customers, an excellent investment option i.e. Digital Gold.'
        },



    ];

    const bottomListItems = [
        {
            title: 'Guaranteed 24K Gold',
            paragraph: 'Unlike local vendors, with SafeGold, you directly buy from the manufacturer'
        },
        {
            title: 'Sell anytime from home',
            paragraph: 'Sell anytime, without going anywhere and receive money direct in your account.'
        },
        {
            title: 'Earn income on gold',
            paragraph: 'You can lend digital gold to SafeGold-verified borrowers and earn monthly rental income in the form of gold.'
        },
        {
            title: 'Safety guaranteed',
            paragraph: 'Unlike physical gold, you don’t have to worry about theft or expensive locker fees. You gold is stored in bank-grade lockers free of cost.'
        },
        {
            title: 'Convert to physical gold',
            paragraph: 'You can convert your digital gold to physical gold anytime in the form of coins or jewellery through our partners.'
        },
        {
            title: 'Buy as low as ₹10',
            paragraph: 'Digital does not require to invest a large sum of money. You can invest based on your budget'
        },
    ];



    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>
                    {GetTranlatedValue(InvestmentServicePageData?.INVESTMENTSERVICES?.digitalgoldheading)}
                </h1>

                <div className='aeps-all-content'>
                    {InvestmentServicePageData.INVESTMENTSERVICES.digitalgoldpara.map((sec, i) => (
                        <div>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                 <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                             </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p>{GetTranlatedValue(sec.title)}</p>
                                    <p className='mt-2'>{GetTranlatedValue(sec.title1)}</p>
                                    <p className='mt-2'>{GetTranlatedValue(sec.title2)}</p>
                                    <p className='mt-2'>{GetTranlatedValue(sec.title3)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <h3 style={{ color: "#333", textAlign: "left", fontSize: 16, paddingBottom: 10 }}>
                    {GetTranlatedValue(InvestmentServicePageData?.INVESTMENTSERVICES?.digitalgolbelow)}
                </h3>
                <ul className="bottom-list">
                    {InvestmentServicePageData.INVESTMENTSERVICES.digitalgoldlist.map((sec, i) => (
                        <li>
                            <div className='d-flex pb-2 gap-2'>
                                <div className='mt-1'>
                                    <img src={dot} style={{ width: 17 }}></img>
                                </div>
                                <div>
                                    <p><strong>{GetTranlatedValue(sec.title)}</strong></p>
                                    <p>{GetTranlatedValue(sec.subtitle)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className='loginlink'>
                <a href={`${window.location.origin}/version2/auth/login`}><p> {GetTranlatedValue(InvestmentServicePageData?.INVESTMENTSERVICES?.fordeatils)}</p></a>
                </div>
            </div>
        </div>
    );
};

export default CspDigitalGold;
