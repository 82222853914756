import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import { PaymentServicePageData } from '../../../Data/Services/PaymentServiceData';
import { GetTranlatedValue } from '../../../Data/LanguageContext';

const CspCashcollection = () => {


    const data2 = [
        {
            title: 'In rural areas, where banking infrastructure is limited and digital literacy levels may be lower, cash remains the primary mode of transaction. However, managing cash efficiently can be challenging for both individuals and businesses. This is where cash management services enabled through the BBPS come into play.'
        },

    ];

    const ServicesAeps = [

        {
            title: 'Convenience:',
            paragraph: 'By leveraging the BBPS network at the customer service points rural residents can make utility bill payments conveniently at nearby agent locations. These CSPs act as touchpoints for bill payments, accepting cash from customers and facilitating the settlement of bills electronically. This convenience eliminates the need for rural residents to travel long distances to make bill payments or stand in long queues at utility offices.'
        },

        {
            title: 'Security and Transparency:',
            paragraph: 'Cash management services offered through the BBPS prioritize security and transparency. Availability of digital records of transactions provide transparency, allowing customers to track their payments and receipts easily.'
        },


    ];


    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 20 }}>Cash Collection</h1>
                <div className='aeps-all-content'>
                    {data2.map((item, index) => (
                        <div key={index}>
                            {/* <h4 style={{ color: "#000" }}>{item.title}</h4> */}
                        </div>
                    ))}
                </div>
                <ul className="bottom-list">
                    {PaymentServicePageData.PAYMENTSERVICES.cashcollectioncontent.map((sec, i) => (
                        <li key={"sec-cashcoll" + i}>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div> */}
                                <div>
                                    <p><strong>{GetTranlatedValue(sec.title)}</strong></p>
                                    <p>{GetTranlatedValue(sec.subtitle)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                    <div className='loginlink'>
                    <a href={`${window.location.origin}/version2/auth/login`}><p> {GetTranlatedValue(PaymentServicePageData?.PAYMENTSERVICES?.fordeatils)}</p></a>
                    </div>
                </ul>
                {/* <img src='https://mobisafar.com/assets/images/cash-collection.png' style={{width:460, padding:"20px 0"}}></img> */}


            </div>
        </div>
    );
};

export default CspCashcollection;
