import React, { forwardRef } from 'react';
import CSPTitle from '../Home/Assets/tagline.png';
import CSPTitleMobile from '../Home/Assets/mobile-life-csp.png';
import { HomePageData } from '../../Data/HomePageData';
import { GetTranlatedValue } from '../../Data/LanguageContext';

export const ThirdSection = forwardRef((props, ref) => {
    const sectionData = [
        {
            id: 1,
            title: 'Instant Opportunity',
            subtitle: 'Business from your Neighbourhood',
        },
        {
            id: 2,
            title: 'Instant Access',
            subtitle: 'Onboarding & document execution take twice as long',
        },
        {
            id: 3,
            title: 'Instant Income',
            subtitle: 'Real time Commission on every Transaction*',
        },
        {
            id: 4,
            title: 'Instant Support',
            subtitle: 'Help is a click or a call away',
        },
    ];

    return (
        <div className="bg-light" ref={ref}>
            <div className="container cspin-growth">
                <div className="text-center">
                    <div className='text-center d-flex  justify-content-center'>
                        <img src={CSPTitle} className='img-fluid cspin-incomefix d-none d-md-block' alt='cspin' />
                    </div>
                    <div className='text-center d-flex  justify-content-center'>
                        <img src={CSPTitleMobile} className='img-fluid cspin-incomefix d-block d-md-none' alt='cspin' />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-10 col-md-10 col-sm-12 mx-auto csp-info">
                       <p>{GetTranlatedValue(HomePageData?.OFFERINGSECTION?.offerparagraph)}<span style={{fontWeight:600}}>{GetTranlatedValue(HomePageData?.OFFERINGSECTION?.offerparagraph1)}</span></p>                 
                    </div>
                </div>
            </div>
        </div>
    );
});



