import React from 'react'
import './style.css'
import ShieldCard from '../WhyCSP/dashboard.svg'
import SimpleCard from '../WhyCSP/shield-card.svg'
import SmartCard from '../WhyCSP/smart-bulb.svg'
import { HomePageData } from '../../Data/HomePageData'
import { GetTranlatedValue } from '../../Data/LanguageContext'
import pinimg from '../WhyCSP/green_underline.svg'

export const NinthSection = () => {
    const sliderData = [
        {
            id: 1,
            title: {
                HINDI: "Simple",
                ENGLISH: "Simple",
                MARATHI: "Simple"
            },
            icon: ShieldCard,
            subtitle: {
                HINDI: "3 चरणों में पंजीकरण: त्वरितआईडी एक्टिवेशन, आसान इंटरफेस, सिंगल साइन इन",
                ENGLISH: "Registration in 3 steps: Instant Activation, Easy Interface, Single Sign in",
                MARATHI: "3 चरणांमध्ये नोंदणी: झटपट सक्रियकरण, सुलभ इंटरफेस, सिंगल साइन इन"
            },
            list: [
                {
                    HINDI: "3 चरणों में पंजीकरण",
                    ENGLISH: "Registration in 3 steps",
                    MARATHI: "3 चरणांमध्ये नोंदणी"
                },
                {
                    HINDI: "त्वरित एक्टिवेशन",
                    ENGLISH: "Instant Activation",
                    MARATHI: "झटपट सक्रियकरण"
                },
                {
                    HINDI: "आसान इंटरफेस",
                    ENGLISH: "Easy Interface",
                    MARATHI: "सुलभ इंटरफेस"
                },
                {
                    HINDI: "सिंगल साइन इन",
                    ENGLISH: "Single Sign in",
                    MARATHI: "सिंगल साइन इन"
                }
            ]
        },
        {
            id: 2,
            title: {
                HINDI: "Secure",
                ENGLISH: "Secure",
                MARATHI: "Secure"
            },
            icon: SimpleCard,
            subtitle: {
                HINDI: "सुरक्षित डेटा विनिमय तंत्र, प्राधिकरण और प्रमाणीकरण प्रक्रिया",
                ENGLISH: "Secure Data Exchange Mechanism, Authorization and Authentication Process",
                MARATHI: "सुरक्षित डेटा एक्सचेंज यंत्रणा, अधिकृतता आणि प्रमाणीकरण प्रक्रिया"
            },
            list: [
                {
                    HINDI: "सुरक्षित डेटा विनिमय तंत्र,",
                    ENGLISH: "Secure Data Exchange ",
                    MARATHI: "डेटा एक्सचेंज यंत्रणा"
                },
                {
                    HINDI: "प्राधिकरण और प्रमाणीकरण प्रक्रिया",
                    ENGLISH: "Authentication Process",
                    MARATHI: "प्रमाणीकरण प्रक्रिया"
                }
            ]
        },
        {
            id: 3,
            title: {
                HINDI: "Smart",
                ENGLISH: "Smart",
                MARATHI: "Smart"
            },
            icon: SmartCard,
            subtitle: {
                HINDI: "सिंगल वॉलेट, इंस्टेंट सेटलमेंट, वॉलेट लोड और कमीशन, एनालिटिकल डैशबोर्ड",
                ENGLISH: "Single wallet, Instant Settlement, Wallet load and Commission, Analytical Dashboard",
                MARATHI: "सिंगल वॉलेट, इन्स्टंट सेटलमेंट, वॉलेट लोड आणि कमिशन, ॲनालिटिकल डॅशबोर्ड"
            },
            list: [
                {
                    HINDI: "सिंगल वॉलेट",
                    ENGLISH: "Single wallet",
                    MARATHI: "सिंगल वॉलेट"
                },
                {
                    HINDI: "इंस्टेंट सेटलमेंट",
                    ENGLISH: "Instant Settlement",
                    MARATHI: "इन्स्टंट सेटलमेंट"
                },
                {
                    HINDI: "वॉलेट लोड और कमीशन",
                    ENGLISH: "Wallet load and Commission",
                    MARATHI: "वॉलेट लोड आणि कमिशन"
                },
            ]
        }
    ];

    return (
        <div className="container ninth-section" style={{ paddingTop: 30, paddingBottom: 120 }}>
            <div className='row'>
                {sliderData.map((sec, i) => (
                    <div key={i} className='col-lg-4 col-md-4 col-sm-12 p-3' style={{height:250}}>
                        <div className='w-100 p-4 d-flex gap-4  h-100' style={{ backgroundColor: '#2c5363', borderRadius: 5 }}>
                            <div className='rounded-3 d-flex justify-content-start h-100' style={{ backgroundColor: "#2C5363", width:"60%" }}>
                                <img src={sec.icon} className='img-fluid' style={{ height: 50, opacity:0.8, width:100}} alt={sec.title.ENGLISH} />
                            </div>
                            <div className='h-100'>
                                <h6 className=' mt-1 fw-bold text-white'>{GetTranlatedValue(sec.title)}</h6>
                                <img className='' src={pinimg} alt="underline"  width={"80%"} style={{opacity:0.8}}/>

                                {/* <p className='text-white mt-4'>
                                {GetTranlatedValue(sec.subtitle)}
                            </p> */}
                                <ol className='mt-2  list-with-dots p-0' style={{ listStyle: "decimal" }}>
                                    {sec.list.map((item, index) => (
                                        <li key={index} className='d-flex align-items-center' style={{ gap: 20, fontWeight: 600 }}>
                                            • {GetTranlatedValue(item)}
                                        </li>
                                    ))}
                                </ol>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
