import React, { useState, useEffect } from 'react';
import '../Banking/CspBankingServices/CspBankingServices.css';
import { GetTranlatedValue } from '../../Data/LanguageContext.js';
import { egovernanceServicePageData } from '../../Data/Services/EGovernanceData.jsx';

const services = [
  { "title": "Government Services", "content": require('../Egov/EgovServices/CspEgovServices.js').default },
  { "title": "Government Scheme", "content": require('../Egov/EgovServices/CspEgovServices.js').default },
  { "title": "Government Registration", "content": require('../Egov/EgovServices/CspEgovServices.js').default },
];

const CourierService = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    setSelectedService(services[0].title);
    setContentVisible(true);
  }, []);

  const handleServiceClick = (title) => {
    setContentVisible(false);
    setTimeout(() => {
      setSelectedService(title);
      setContentVisible(true);
    }, 300);
  };

  const ServiceContent = selectedService ? services.find(service => service.title === selectedService).content : null;

  return (
    <div className="cspbankingservices">
      <div className='container'>
      <div style={{ marginBottom: 54, }}>
          <h2 style={{ textAlign: "center", marginBottom: 20, fontWeight: 700, color: "#2C5363" }}>
            {GetTranlatedValue(egovernanceServicePageData?.EGOVERNANCESERVICES?.egovernanceheading2)}
          </h2>
          <div className='aeps-all-content'>
            {egovernanceServicePageData.EGOVERNANCESERVICES.egovernanceheadingpara.map((item, i) => (
              <div key={i}>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title)}</h4>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title1)}</h4>
                <h4 style={{ color: "#000", }}>{GetTranlatedValue(item.title2)}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 csp-col-1 col-md-4'>
            {services.map((item) => (
              <div key={item.title} className={`cspbankingservicescontent ${selectedService === item.title ? 'active' : ''}`} onClick={() => handleServiceClick(item.title)}>
                <h2>{item.title}</h2>
              </div>
            ))}
          </div>
          <div className='col-lg-8 csp-col-2 col-md-8'>
            <div className={`csp-content-container ${contentVisible ? '' : 'hidden'}`}>
              {selectedService && ServiceContent && <ServiceContent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourierService;
