export const courierServicePageData = {
    COURIERERVICES: {
        courierheading: {
            HINDI: "ई-गवर्नेंस सेवाएँ",
            ENGLISH: "Courier",
            MARATHI: "ई-गव्हर्नन्स सेवा"
        },
        courierheading2: {
            HINDI: "स्कोप और ऑफरिंग",
            ENGLISH: "Scope And Offering",
            MARATHI: "व्याप्ती आणि ऑफरिंग"
        },
    }
}