export const egovernanceServicePageData = {
    EGOVERNANCESERVICES: {
        egovernanceheading: {
            HINDI: "ई-गवर्नेंस सेवाएँ",
            ENGLISH: "E-Governance Services",
            MARATHI: "ई-गव्हर्नन्स सेवा"
        },
        egovernanceheading2: {
            HINDI: "स्कोप और ऑफरिंग",
            ENGLISH: "Scope And Offering",
            MARATHI: "व्याप्ती आणि ऑफरिंग"
        },
        egovernanceheadingpara: [
            {
                title: {
                    HINDI: "ग्राहक सुविधा को अपने मूल उद्देश्य के अनुरूप, हमारे प्लेटफॉर्म पर विभिन्न सरकारी योजनाओं और नागरिक केंद्रित सेवाओं से संबंधित जानकारी उपलब्ध है। यह जानकारी विभिन्न सरकारी वेबसाइटों पर उपलब्ध जानकारी से एकत्रित की गई है। हमारे ग्राहकों को और अधिक सुविधा प्रदान करने के लिए, उपलब्ध जानकारी को उनकी आवश्यकता के अनुसार विभिन्न मापदंडों के आधार पर क्रमबद्ध किया जा सकता है। हमारे कम्युनिटी इंटरप्रेन्योर इस प्रक्रिया में हमारे ग्राहकों की सहायता करेंगे।",
                    ENGLISH: "In line with our objective of customer convenience as our core, We have on our platform information related to various government schemes and citizen centric services. This information has been pooled from the information available on various government websites . To further facilitate our customers ,the available information can further be sorted on basis of various parameters as per their requirement. a Community Entrepreneur’s would be assisting our customers in this process.",
                    MARATHI: "ग्राहकांच्या सोयी हाच आमचा मूळ उद्देश आहे या अनुषंगाने, आमच्या प्लॅटफॉर्मवर विविध सरकारी योजना आणि नागरिक केंद्रित सेवांशी संबंधित माहिती आहे. विविध सरकारी संकेतस्थळांवर उपलब्ध माहितीवरून ही माहिती एकत्रित करण्यात आली आहे. आमच्या ग्राहकांना अधिक सुविधा देण्यासाठी, उपलब्ध माहितीची त्यांच्या गरजेनुसार विविध पॅरामीटर्सच्या आधारे क्रमवारी लावली जाऊ शकते. आमची कोम्मुनिटी एन्त्रेप्रेनेऊर आमच्या ग्राहकांना या प्रक्रियेत मदत करेल."
                },
                title1: {

                    HINDI: " इस जानकारी की उपलब्धता और विभिन्न नागरिक केंद्रित सेवाओं के लिंक हमारे कम्युनिटी इंटरप्रेन्योर के लिए ग्राहक जुड़ाव और सेवा के लिए एक बड़ी गुंजाइश पैदा करते हैं।",
                    ENGLISH: "Availability of this information and links to various citizen centric services creates a huge scope for customer engagement and service for a Community Entrepreneur.",
                    MARATHI: "या माहितीची उपलब्धता आणि विविध नागरिक केंद्रित सेवांच्या लिंक्समुळे आमच्या ग्रहकप्रतिनिधीसाठी ग्राहक संलग्नता आणि सेवेसाठी मोठा वाव निर्माण होतो."
                },
                title2: {

                    HINDI: "कृपया ध्यान दें कि हम विभिन्न सरकारी वेबसाइटों पर उपलब्ध जानकारी को एकत्रित कर रहे हैं तथा उसे ग्राहकों को बिना किसी परिवर्तन के उसी रूप में उपलब्ध करा रहे हैं, जैसा कि संबंधित वेबसाइटों पर उपलब्ध है तथा इसका एकमात्र उद्देश्य ग्राहक सेवा को सुविधाजनक बनाना है। यह एक गैर-प्रभार्य सेवा है.",
                    ENGLISH: "Please note that we are only pooling the information available at various government websites and making it available to customers strictly as they appear in the respective websites without any alteration whatsoever and with a sole purpose of facilitating the customer service. This is a non-chargeable service.",
                    MARATHI: "कृपया लक्षात घ्या की आम्ही फक्त विविध सरकारी वेबसाइट्सवर उपलब्ध असलेली माहिती एकत्र करत आहोत आणि ग्राहकांना ती उपलब्ध करून देत आहोत कारण ती संबंधित वेबसाइट्समध्ये कोणताही बदल न करता आणि ग्राहक सेवेची सुविधा करण्याच्या एकमेव उद्देशाने दिसत आहे. ही एक विनाशुल्क सेवा आहे."
                }
            }
        ],

        dscheading: {
            HINDI: "डीएससी: डिजिटल हस्ताक्षर प्रमाणपत्र",
            ENGLISH: "DSC : Digital Signatures Certificate",
            MARATHI: "DSC :डिजिटल स्वाक्षरी प्रमाणपत्र"
        },
        dscservicepara: [
            {
                title: {
                    HINDI: "भारत में डिजिटल हस्ताक्षर की संभावनाएं काफी हैं, जो तेजी से डिजिटलीकरण और 2000 के सूचना प्रौद्योगिकी अधिनियम द्वारा स्थापित एक मजबूत नियामक ढांचे से प्रेरित है। डिजिटल इंडिया जैसी पहल सार्वजनिक सेवाओं में दक्षता बढ़ाने और निर्भरता कम करने में मदद करने के लिए इलेक्ट्रॉनिक दस्तावेज़ीकरण के उपयोग की सुविधा प्रदान कर रही है । ई-कॉमर्स के विकास के साथ, डिजिटल हस्ताक्षर सुरक्षित लेनदेन, पहचान सत्यापन और अनुबंध प्रामाणिकता के लिए महत्वपूर्ण होते जा रहे हैं, खासकर वित्त और रियल एस्टेट जैसे क्षेत्रों में।",
                    ENGLISH: "The potential for digital signatures in India is considerable, catalysed by rapid digitization and a robust regulatory framework established by the Information Technology Act of 2000. Initiatives like Digital India are facilitating the use of electronic documentation, to help enhance efficiency in public services and decreases dependency on paper. With the growth of e-commerce, digital signatures are becoming vital for secure transactions, identity verification, and contract authenticity, especially in sectors such as finance and real estate. ",
                    MARATHI: "भारतात डिजिटल स्वाक्षरीची क्षमता लक्षणीय आहे, जलद डिजिटायझेशन आणि 2000 च्या माहिती तंत्रज्ञान कायद्याद्वारे स्थापित केलेल्या मजबूत नियामक फ्रेमवर्कद्वारे उत्प्रेरित केले गेले आहे. डिजिटल इंडिया सारख्या उपक्रमांमुळे सार्वजनिक सेवांमध्ये कार्यक्षमता वाढवण्यासाठी आणि अवलंबित्व कमी करण्यात मदत करण्यासाठी इलेक्ट्रॉनिक दस्तऐवजीकरणाचा वापर सुलभ होत आहे. कागदावर ई-कॉमर्सच्या वाढीसह, विशेषत: वित्त आणि रिअल इस्टेट सारख्या क्षेत्रांमध्ये सुरक्षित व्यवहार, ओळख पडताळणी आणि कराराच्या सत्यतेसाठी डिजिटल स्वाक्षरी महत्त्वपूर्ण होत आहेत."
                },
                title1: {
                    HINDI: "इसके अलावा, डिजिटल हस्ताक्षर पारंपरिक हस्ताक्षरों की तुलना में बेहतर सुरक्षा प्रदान करते हैं, धोखाधड़ी को कम करने और डेटा अखंडता सुनिश्चित करने में मदद करते हैं। डिजिटल हस्ताक्षरों के बाजार का 2022 से 2027 तक लगभग 25% की चक्रवृद्धि वार्षिक वृद्धि दर (सीएजीआर) से विस्तार होने का अनुमान है (स्रोत: मार्केटसैंडमार्केट्स), जो विभिन्न उद्योगों में इस तकनीक की बढ़ती स्वीकार्यता को दर्शाता है। जैसे-जैसे अधिक संगठन अपने संचालन में डिजिटल हस्ताक्षर शामिल करेंगे, इस समाधान की मांग बढ़ती रहेगी, जिससे यह भारत के डिजिटल विकास का एक अनिवार्य हिस्सा बन जाएगा।",
                    ENGLISH: "Moreover, digital signatures offer enhanced security compared to traditional signatures, helping to reduce fraud and ensure data integrity. The market for digital signatures is projected to expand at a compound annual growth rate (CAGR) of approximately 25% from 2022 to 2027 (source: MarketsandMarkets), reflecting the increasing adoption of this technology across various industries. As more organizations incorporate digital signatures into their operations, the demand for this solution will continue to grow, making it an essential part of India's digital evolution.",
                    MARATHI: "शिवाय, डिजिटल स्वाक्षरी पारंपारिक स्वाक्षरींच्या तुलनेत वर्धित सुरक्षा देतात, फसवणूक कमी करण्यात आणि डेटा अखंडता सुनिश्चित करण्यात मदत करतात. डिजिटल स्वाक्षरीसाठी बाजारपेठ 2022 ते 2027 (स्रोत: MarketsandMarkets) च्या चक्रवाढ वार्षिक वाढ दराने (CAGR) 2022 ते 2027 पर्यंत विस्तारण्याचा अंदाज आहे, जे विविध उद्योगांमध्ये या तंत्रज्ञानाचा वाढता अवलंब प्रतिबिंबित करते. जसजसे अधिक संस्था त्यांच्या ऑपरेशन्समध्ये डिजिटल स्वाक्षरींचा समावेश करतात, तसतसे या सोल्यूशनची मागणी वाढतच जाईल, ज्यामुळे हा भारताच्या डिजिटल उत्क्रांतीचा एक आवश्यक भाग होईल."
                },
            },
        ],


        egovernanceserviceheading: {
            HINDI: "सरकारी सेवाएँ एवं योजना",
            ENGLISH: "Government Services & Scheme",
            MARATHI: "सरकारी सेवा आणि योजना"
        },
        egovernanceserviceervicepara: [
            {
                title: {
                    HINDI: "किसी भी सरकारी योजना और सेवा का प्राथमिक उद्देश्य समुदाय या समाज का कल्याण है। सीएसपी अपने मंच पर विभिन्न सरकारी और सेवाओं का सारांश लाता है। आप किसी विशेष योजना या सेवा पर क्लिक करके इन योजनाओं और सेवाओं से संबंधित वेबसाइटों तक पहुंच सकते हैं।",
                    ENGLISH: "The Primary objective of any Government Scheme and Service is the welfare of community or society. CSP brings to on its platform a synopsis of various government and services. You can access the websites related to these schemes and services by simply clicking on a particular scheme or service. ",
                    MARATHI: "कोणत्याही सरकारी योजना आणि सेवेचे प्राथमिक उद्दिष्ट समाजाचे किंवा समाजाचे कल्याण असते. CSP आपल्या प्लॅटफॉर्मवर विविध सरकारी आणि सेवांचा सारांश घेऊन येतो. तुम्ही विशिष्ट योजना किंवा सेवेवर क्लिक करून यायोजना आणि सेवांशी संबंधित वेबसाइटवर प्रवेश करू शकता."
                },
                title1: {
                    HINDI: " इन कल्याणकारी योजनाओं के बारे में जानकारी सही वर्ग तक पहुंचाने में मदद करना महत्वपूर्ण है। सरकार द्वारा विभिन्न कल्याणकारी पहलों के बारे में प्रासंगिक जानकारी प्रसारित करने में मदद करके अपने समुदाय के प्रति अपना योगदान दें।",
                    ENGLISH: "Helping the information about these welfare schemes percolate to the right segment is critical. Make your contribution towards your community by helping percolate the relevant information about the various welfare initiatives by the Government. ",
                    MARATHI: "या कल्याणकारी योजनांची माहिती योग्य विभागापर्यंत पोचण्यास मदत करणे महत्त्वाचे आहे. सरकारच्या विविध कल्याणकारी उपक्रमांबद्दल संबंधित माहिती पसरवण्यास मदत करून आपल्या समुदायासाठी आपले योगदान द्या."
                },
            },
        ],

        fordeatils: {
            HINDI: "विवरण के लिए csp.in पर लॉग इन करें",
            ENGLISH: "For details log in to csp.in",
            MARATHI: "तपशीलांसाठी csp.in वर लॉग इन करा"
        },



        egovernanceregistrationheading: {
            HINDI: "सरकारी सेवाएँ एवं योजना",
            ENGLISH: "Government Registration",
            MARATHI: "सरकारी सेवा आणि योजना"
        },
        egovernanceregistrationpara: [
            {
                title: {
                    HINDI: "हम स्टार्टअप इंडिया, दुकान और प्रतिष्ठानों, एमएसएमई, एफएसएसएआई, टैन, आयातक निर्यातक कोड, पीएफ आदि के लिए पंजीकरण जैसी विभिन्न दस्तावेज प्रक्रियाओं में आपके ग्राहकों की जरूरतों को पूरा करने में आपकी मदद करेंगे।",
                    ENGLISH: "We will help you to cater to the needs of your customers in various documentation processes like Registrations for Startup India, Shop and Establishments, MSME, FSSAI, TAN, Importer Exporter code, PF etc.  ",
                    MARATHI: "स्टार्टअप इंडिया, शॉप आणि आस्थापना, MSME, FSSAI, TAN, आयातक निर्यातक कोड, PF इत्यादींसाठी नोंदणी अशा विविध दस्तऐवज प्रक्रियांमध्ये तुमच्या ग्राहकांच्या गरजा पूर्ण करण्यासाठी आम्ही तुम्हाला मदत करू."
                },
               
            },
        ],

        fordeatils: {
            HINDI: "विवरण के लिए csp.in पर लॉग इन करें",
            ENGLISH: "For details log in to csp.in",
            MARATHI: "तपशीलांसाठी csp.in वर लॉग इन करा"
        },
    }
}