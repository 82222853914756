import React from 'react'
import { Link } from 'react-router-dom'
import './Desclaimer.css'

const Desclaimer = () => {
    return (
        <div className='refund-container px-3'>
            <div className='refund row'>
                <h1 className='text-center'>
                    Desclaimer
                </h1>
                <p style={{ textTransform: "capitalize" }}><strong>M/s CSP INDIA NETWORK PVT LTD,</strong> (“company”, “we”, “us” and “our”), a private company established under the laws of India, Companies Act 2013 having its registered office at  <strong>Corp.Off 207, City Avenue by Kolte Patil, near Range Rover ShowRoom, Wakad.</strong>
                    (hereinafter referred to as the <strong>“Company”</strong>  (where such expression shall, unless repugnant to the context thereof, be deemed to include its respective legal heirs, representatives, administrators, permitted successors and assigns).</p>

                <p style={{ textTransform: "capitalize" }}>The Company ensures steady commitment to your usage of the Website with regard to the protection of your privacy and invaluable information. This document contains information about the Website <Link>www.csp.in</Link> (hereinafter referred as the “Platform”).</p>

                <p style={{ textTransform: "capitalize" }}>The company will not be responsible for any manner of loss, damage, or injury suffered by users whatsoever, including death, that might occur as a direct / indirect result of the use of the website, or the use or availment of the services (as defined by the terms) contained therein, even if the company has been made aware of any such risks. This includes, but is not limited to, loss of revenue/data resulting from delays, non-deliveries, missed deliveries, or service interruptions as may occur because of any act/omission attributable to the company or any other third party. This disclaimer of liability also applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of records, whether for breach of contract, tortuous behavior, negligence, or under any other cause of action.</p>

                <p style={{ textTransform: "capitalize" }}>The user (as defined by the terms) hereby expressly agrees and accepts that the company shall not be deemed liable for any damage/hurt/inconvenience caused to the user through the course of the provision of services, or as a result of the user’s actions or inactions. The company makes no representations or warranties as to the conduct of its other users, or the viability/suitability of the website. The company takes no responsibility for any content that the user sends or receives from other users or third-party posts through the website. Any material downloaded, viewed, or otherwise obtained through the use of the service/website is accessed at the user’s sole discretion and risk, and the user hereby expressly agrees to the same.</p>

                <p style={{ textTransform: "capitalize" }}>The services on the website are intended to be subject to availability, without any promises or guarantees on the same by the company, and while certain information available on the website is the property of the company, and the company endeavors to keep the said information updated and accurate, the company shall make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the website, or the information, customers, services, or related graphics contained on the website, or its fitness for any purpose. Any reliance the user places on such information is therefore strictly at the user’s own risk.</p>

                <p style={{ textTransform: "capitalize" }}>Despite every effort to provide an accurate scope of services provided, there might be circumstances where such services might not be able to get executed due to regulatory or practical concerns. The company will not accept responsibility for any delay, non-execution of service, or other issues relating to the services.</p>

                <p style={{ textTransform: "capitalize" }}>All the intellectual property and content on the website, or visible as part of the services, is the sole and exclusive property of the company, and no use, misuse, reproduction, publication, or manipulation of the same shall be permitted, except as may be expressly permitted by the company from time to time. Any such act shall result in the company initiating appropriate legal action, and the user does hereby expressly acknowledge the same.</p>

                <p style={{ textTransform: "capitalize" }}>The company bears no responsibility whatsoever for any consequences arising from the use of the said services or website or suggestions and methods given by the company to the users. The use of the services, website, and the website is the sole responsibility of the user (or legally authorized person acting on behalf of the user). Any negligence on the part of the user in acting on the same shall not be construed as imposing any liability, direct or indirect, on the company/website.</p>

                <p style={{ textTransform: "capitalize" }}>The company hereby disclaims all responsibility and liability to the user for any outcome (incidental, direct, indirect, or otherwise) from the use of the services/website. In no event will the company be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of the website, including personal injury and/or death.</p>

                <p style={{ textTransform: "capitalize" }}>Through the website, the user may be able to link to other websites or websites which are not under the control of the company. The company has no control over the nature, content, and availability of such third-party websites. The inclusion of any links to such third-party websites on the company’s website does not in any manner imply a recommendation or endorsement of the views, content, or services provided/displayed therein. Every effort is made to keep the website up and running smoothly. However, the company takes no responsibility for, and will never be deemed liable for, the website being temporarily or permanently unavailable due to technical issues beyond the company’s control.</p>

                <p style={{ textTransform: "capitalize" }}>To the maximum extent permitted by applicable law, the company shall have no liability related to user content arising under intellectual property rights, libel, privacy, publicity, obscenity, or any other laws. The company also hereby disclaims all liability with respect to the misuse, loss, modification, or unavailability of any user content.</p>

                <p style={{ textTransform: "capitalize" }}>The user understands and agrees that any material or data downloaded or otherwise obtained through the website is done entirely at their own discretion and risk, and they will be solely responsible for any damage to their computer systems or loss of data that results from the downloading of such material or data. The company shall not be responsible for any typographical errors. The company accepts no liability for any errors or omissions, with respect to any information provided to the user, whether on behalf of itself or third parties.</p>

                <p style={{ textTransform: "capitalize" }}>The user agrees that their use of the website and/or availment of or subscription to the services provided by the company shall be at their sole risk. To the fullest extent permitted by law, the company and its subsidiaries, affiliates, suppliers, and agents disclaim all warranties, express, implied, statutory, or otherwise, and make no warranties, representations, or guarantees in connection with the website, the services offered therein, any data, materials, submitted content, relating to the quality, suitability, truth, accuracy, or completeness of any information or material contained or presented on this website, including without limitation the materials, data, and submitted content of other users of this site or other third parties. Unless otherwise explicitly stated, to the maximum extent permitted by applicable law, the website, the services offered therein, data, materials, user-submitted content, and any information or material contained or presented on the website is provided on an “as is,” “as available,” and “where-is” basis.

                    The company does not provide any warranties against errors, mistakes, or inaccuracies of data, content, information, materials, or substance of the website or submitted content. This includes any unauthorized access to or use of the company’s secure servers and/or all personal & financial information stored therein, any bugs, viruses, trojan horses, or similar threats which may be transmitted to or through the website by any third party, any interruption or cessation of transmission to or from the website, any defamatory, offensive, or illegal conduct of any third party, vendor, or service provider, or any loss or damage of any kind incurred as a result of the use of any data, content, information, materials, or substance of the website, or submitted content posted, emailed, transmitted, or otherwise made available via the website.

                    The company will not be a party to or in any way responsible for monitoring any transaction between the user and any other party, including other users, third-party service professionals, or services. As with the use of any service, and the publishing or posting of any material through any medium or in any environment, the user should use their best judgment and exercise caution before utilizing any part of the website/services.</p>
            </div>
        </div>
    )
}

export default Desclaimer